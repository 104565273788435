"use client";

/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */

import React, { useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */

import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";
import { states } from "@/data/states";
import { useDropdownStore } from "@/core/Dropdown";

import { get, useFormContext } from "react-hook-form";
import { Label } from "@/components/ui/label";

export type State = (typeof states)[number];

interface StateDropdownProps {
    name: string;
    label?: string;
}

const StateDropdown = ({ name, label }: StateDropdownProps) => {
    const { countryValue, stateValue, openStateDropdown, setOpenStateDropdown, setStateValue } = useDropdownStore();

    const {
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    useEffect(() => {
        setValue(name, stateValue);
    }, [stateValue]);

    const SD = states as State[];
    const S: State[] = SD.filter((state) => state.country_code === countryValue);

    // console.log(stateValue);
    return (
        <div className="grid gap-2">
            {label && <Label htmlFor={name}>{label}</Label>}

            <Popover open={openStateDropdown} onOpenChange={setOpenStateDropdown}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openStateDropdown}
                        name={name}
                        id={name}
                        // className="w-[300px] cursor-pointer justify-between rounded-[6px] border !border-[#27272a] !bg-[#0f0f11] hover:!bg-[#0f0f11] focus:!bg-[#0f0f11] focus:!outline-none focus:!ring-2 focus:!ring-[#0f0f11] focus:!ring-offset-2 focus:!ring-offset-[#0f0f11] disabled:!cursor-not-allowed disabled:!opacity-50"
                        disabled={!countryValue || S.length === 0}>
                        {stateValue ? (
                            <div className="flex items-end gap-2">
                                <span>{S.find((state) => state.state_code === stateValue)?.name}</span>
                            </div>
                        ) : (
                            <span>Select State...</span>
                        )}
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-max p-0" align="start">
                    <Command>
                        <CommandInput placeholder="Search state..." />
                        <CommandList>
                            <CommandEmpty>No state found.</CommandEmpty>
                            <ScrollArea className={"[&>[data-radix-scroll-area-viewport]]:max-h-[300px]"}>
                                <CommandGroup>
                                    {/* <CommandItem>Hello</CommandItem> */}
                                    {S.map((state: State) => (
                                        <CommandItem
                                            key={state.id}
                                            value={state.name}
                                            onSelect={(currentValue) => {
                                                // console.log(currentValue);
                                                const value = currentValue === state.name ? state.state_code : "";
                                                setStateValue(value);
                                                setOpenStateDropdown(false);
                                                // setValue(name, value);
                                            }}
                                            className="flex cursor-pointer items-center justify-between text-xs hover:!bg-[#27272a] hover:!text-white">
                                            <div className="flex items-end gap-2">
                                                <span className="">{state.name}</span>
                                            </div>
                                            <Check className={cn("mr-2 h-4 w-4", stateValue === state.state_code ? "opacity-100" : "opacity-0")} />
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </ScrollArea>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            {errorMessage !== undefined ? <p className="text-error-500 text-xs">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
};

export default StateDropdown;

{
    /* <CommandGroup>
                        <ScrollArea className="h-[300px] w-full">
                            {S.map((state) => (
                                <CommandItem
                                    key={state.id}
                                    value={state.name}
                                    onSelect={(currentValue) => {
                                        setStateValue(currentValue === lowerCase(state.name) ? currentValue : "");
                                        setOpenStateDropdown(false);
                                    }}
                                    className="flex cursor-pointer items-center justify-between text-xs hover:!bg-[#27272a] hover:!text-white">
                                    <div className="flex items-end gap-2">
                                        <span className="">{state.name}</span>
                                    </div>
                                    <Check className={cn("mr-2 h-4 w-4", stateValue === lowerCase(state.name) ? "opacity-100" : "opacity-0")} />
                                </CommandItem>
                            ))}
                            <ScrollBar orientation="vertical" />
                        </ScrollArea>
                    </CommandGroup> */
}
