import { z } from "zod";

export const suppliesSchema = z.object({
    carrier: z.string(),
    packageType: z.array(z.string().min(1)).min(1).nonempty("Please select at least one package type."),
    amount: z.array(z.number().min(1, "Amount must be at least 1")),
    email: z.string(),
    text: z.string().optional(),
    company: z.string()
});
