import axios from "axios";

export const sendWelcomeEmail = async (email: string) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/email/welcome/${email}`);
        console.log(response);
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};
