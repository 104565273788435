import { createContext, useContext, useEffect, useState } from "react";

type FormStepValue = {
    FormSteps: JSX.Element[];
    setFormSteps: React.Dispatch<React.SetStateAction<JSX.Element[]>>;
    steps: {
        label: string;
    }[];
    setSteps: React.Dispatch<
        React.SetStateAction<
            {
                label: string;
            }[]
        >
    >;
};

export const FormStepContext = createContext<FormStepValue>({} as FormStepValue);

// * hook to use FormStepContext
export function useFormSteps() {
    const form = useContext(FormStepContext);

    if (form === undefined) {
        throw new Error("useFormSteps must be used to with a FormContext");
    }

    return form;
}
