import FormTitle from "@/components/composite/Headers/FormTitle";
import Select from "@/components/composite/Inputs/Select";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { pickupDetailsSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import Form from "@/components/composite/Form";
import { Button } from "@/components/ui/button";
import { useStepper } from "@/components/ui/stepper";
import { useFormSteps } from "@/context/FormStepContext";
import { useFormStore } from "@/core/ShipmentFormStore";
import { PickupDetails } from "@shared/ShipmentTypes";
import CreatePickup from "./CreatePickup";

const PickupList = [
    {
        name: "pickup",
        label: "Schedule Pickup"
    },
    {
        name: "dropoff",
        label: "Drop off"
    }
];

const ScheduleList = [
    {
        name: "scheduleNow",
        label: "Schedule Pickup Now"
    },
    {
        name: "scheduleLater",
        label: "Schedule Pickup Later"
    },
    {
        name: "useExisting",
        label: "Use Existing Pickup"
    }
];

export default function PickupOptions() {
    const { prevStep, nextStep } = useStepper();

    const { steps } = useFormSteps();

    const [pickupType, setPickupType] = useState<PickupDetails["pickupType"]>("dropoff");

    const [scheduleType, setScheduleType] = useState<string | undefined>("");

    // console.log(useFormStore.getState().pickupDetails);

    const methods = useForm<z.infer<typeof pickupDetailsSchema>>({
        resolver: zodResolver(pickupDetailsSchema),
        mode: "onChange",
        defaultValues: {
            pickupType: useFormStore.getState().pickupDetails.pickupType || "dropoff",
            carrier: useFormStore.getState().serviceDetails.carrier,
            scheduleType: useFormStore.getState().pickupDetails.scheduleType,
            pickupLocation: useFormStore.getState().pickupDetails.pickupLocation,
            readyTimeStamp: {
                date: useFormStore.getState().pickupDetails.readyTimeStamp?.date,
                time: useFormStore.getState().pickupDetails.readyTimeStamp?.time,
                isoTimeStamp: useFormStore.getState().pickupDetails.readyTimeStamp?.isoTimeStamp
            },
            closeTimeStamp: {
                date: useFormStore.getState().pickupDetails.closeTimeStamp?.date,
                time: useFormStore.getState().pickupDetails.closeTimeStamp?.time,
                isoTimeStamp: useFormStore.getState().pickupDetails.closeTimeStamp?.isoTimeStamp
            }
        }
    });

    useEffect(() => {
        setPickupType(methods.getValues("pickupType"));
        setScheduleType(methods.getValues("scheduleType"));
    }, []);

    const onPickupChange = (value: PickupDetails["pickupType"]) => {
        console.log(value);
        setPickupType(value);

        if (value === "dropoff") {
            methods.setValue("scheduleType", undefined);
            setScheduleType(undefined);
        }
        if (value === "pickup") {
            if (scheduleType === undefined) {
                methods.setValue("scheduleType", "scheduleNow");
                setScheduleType("scheduleNow");
            }
        }
    };

    const onScheduleChange = (value: string) => {
        console.log(value);
        setScheduleType(value);
    };

    const goPrev = () => {
        const data = methods.getValues();
        useFormStore.getState().addPickupDetails(data);
        prevStep();
    };

    const handleClick = (data: z.infer<typeof pickupDetailsSchema>) => {
        onSubmit(data);

        nextStep();
    };

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Handoff</FormTitle>
            </CardHeader>
            <CardContent className="p-4">
                <Form methods={methods} onSubmit={handleClick} className="grid gap-6">
                    <Select name="pickupType" label="Pickup Type" SelectItemList={PickupList} defaultValue={methods.getValues("pickupType")} onChange={onPickupChange} />
                    {pickupType === "pickup" && (
                        <>
                            <Select name="scheduleType" label="Schedule Type" SelectItemList={ScheduleList} defaultValue={methods.getValues("scheduleType")} onChange={onScheduleChange} />
                        </>
                    )}
                    {scheduleType === "scheduleNow" && <CreatePickup />}
                    {/* {scheduleType === "useExisting" && <UseExistingPickup />} */}
                    <div className="flex gap-6">
                        <Button type="button" onClick={goPrev} variant="outline" className="flex-grow">
                            {/* Select Rates */}
                            Back
                        </Button>

                        {/* <Button variant="default" type="submit" onClick={methods.handleSubmit(handleClick)} className="flex-grow"> */}
                        <Button variant="default" type="submit" className="flex-grow">
                            {/* {steps[5].label !== "Customs" ? "Confirm Cost" : "Fill out Customs"} */}
                            Next
                        </Button>
                    </div>
                </Form>
            </CardContent>
        </Card>
    );
}

export const onSubmit = (data: z.infer<typeof pickupDetailsSchema>) => {
    console.log(data);
    useFormStore.getState().addPickupDetails(data);
    console.log(useFormStore.getState().pickupDetails);
    console.log(useFormStore.getState().shipment);
};
