import React, { ReactNode } from "react";

interface PageTitleProps {
    children: ReactNode;
    id?: string;
}

export default function PageTitle({ children, id }: PageTitleProps) {
    return (
        <h1 className="mb-6 flex items-center gap-2 text-2xl font-semibold" id={id}>
            {children}
        </h1>
    );
}
