import { ChevronLeftIcon } from "lucide-react";
import Header from "@/components/layout/Header";
import { Button } from "@/components/ui/button";
import { Outlet, useNavigate } from "react-router-dom";
import { Separator } from "@/components/ui/separator";
import { SidebarNav } from "@/components/layout/SettingsNavigation";
import { Toaster } from "@/components/ui/toaster";
import { useAppContext } from "@/onboarding/context";

const sidebarNavItems = [
    {
        title: "Account Information",
        to: "/settings"
    },
    {
        title: "Billing",
        to: "/settings/payments"
    },
    {
        title: "Carriers",
        to: "/settings/carriers"
    }
];

export default function AccountSettingsLayout() {
    const navigate = useNavigate();
    const {
        setState,
        state: { run, tourActive }
    } = useAppContext();

    const handleClickStart = () => {
        if (location.pathname !== "/settings/carriers") {
            navigate("/settings/carriers");
        }
        setState({ run: true, tourActive: true, stepIndex: 1 });
    };

    return (
        <>
            <div className="flex h-screen items-center justify-center p-4 text-center lg:hidden">
                SnapShip isn't fully optimized for smaller devices yet. For the best experience, please use a desktop or larger screen.
            </div>
            <div className="hidden lg:block">
                <Header />
                <div className="hidden h-full space-y-6 overflow-y-auto p-10 pb-16 md:block">
                    <div className="space-y-0.5">
                        <h1 className=" flex items-center gap-2 text-2xl font-semibold">
                            <Button variant="outline" size="icon" className="h-7 w-7" onClick={() => navigate("/create-shipment")}>
                                <ChevronLeftIcon className="h-4 w-4" />
                            </Button>
                            Settings
                        </h1>
                        <p className="text-muted-foreground">Manage your account settings.</p>
                    </div>
                    <Separator className="my-6" />
                    <div className="flex h-full flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
                        <aside className="-mx-4 lg:w-1/5">
                            <SidebarNav items={sidebarNavItems} />
                        </aside>
                        <div className="flex-1 lg:max-w-2xl">
                            <Outlet />
                            <div className="text-muted-foreground my-6 text-sm">
                                Need help navigating around the app? Click{" "}
                                <span onClick={handleClickStart} style={{ textDecoration: "underline", color: "blue" }} className="cursor-pointer">
                                    here
                                </span>{" "}
                                for a guided tour!
                            </div>
                        </div>
                    </div>
                </div>
                <Toaster />
            </div>
        </>
    );
}
