import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Mail, Package, Cylinder } from "lucide-react";
import { useContext, useEffect } from "react";

import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useFormContext, get } from "react-hook-form";

export default function ShipmentType({ shipmentType, setShipmentType }) {
    const {
        control,
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const handleChange = (value: string) => {
        // console.log(value);
        setShipmentType(value);
        clearErrors("shipmentType");
        setValue("shipmentType", value);
        setValue("deliveryFormat", null);
        setValue("units", null);
        setValue("packages", null);
    };

    useEffect(() => {
        handleChange(getValues("shipmentType"));
    }, []);

    const errorMessage = get(errors, "shipmentType")?.message;

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-base font-medium">Shipment Type</CardTitle>
                <CardDescription>Select which packaging type your shipment will be using.</CardDescription>
            </CardHeader>

            <CardContent>
                {/* <RadioGroup name="ShipmentType">
                    <RadioGroupItem value="Letter" />
                </RadioGroup> */}
                <ToggleGroup type="single" className="grid grid-cols-3 gap-4" onValueChange={handleChange} defaultValue={getValues("shipmentType")} role="shipmentType">
                    <ToggleGroupItem value="Letter" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Mail /> Envelope/Pak
                    </ToggleGroupItem>
                    <ToggleGroupItem value="Package" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Package /> Package
                    </ToggleGroupItem>
                    <ToggleGroupItem value="Tube" className="flex items-center justify-center gap-3 p-2.5" name="shipmentType">
                        <Cylinder /> Triangular Tube
                    </ToggleGroupItem>
                </ToggleGroup>
                {errorMessage !== undefined ? <p className="text-center text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
            </CardContent>
        </Card>
    );
}
