// import Input from "@/components/composite/Inputs/Input";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ParsedPhoneNumber, parsePhoneNumber } from "awesome-phonenumber";
import { useState } from "react";
import { useFormContext, get } from "react-hook-form";

export default function PhoneInput() {
    const {
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors }
    } = useFormContext();

    const [phoneNumber, setPhoneNumber] = useState("");

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;

        setValue("phoneNumber", input);

        let pnu: ParsedPhoneNumber;
        if (input.startsWith("+")) {
            pnu = parsePhoneNumber(input);
        } else {
            pnu = parsePhoneNumber(input, { regionCode: "CA" });
        }

        // console.log(pnu);
        if (pnu.number && pnu.valid) {
            setPhoneNumber(pnu.number.e164);
            setValue("phoneNumber", pnu.number.e164);
            // clearErrors("phoneNumber");
        }
    };

    const errorMessage = get(errors, "phoneNumber")?.message;

    // console.log(errorMessage);

    return (
        <>
            <Label htmlFor={"phoneNumber"}>Phone Number</Label>
            <Input onChange={handleOnChange} id={"phoneNumber"} defaultValue={getValues("phoneNumber") || phoneNumber} />
            {errorMessage !== undefined ? (
                <p role="error" className="text-error-500 text-xs">
                    {errorMessage}
                </p>
            ) : (
                <p className="block text-xs text-red-500"> </p>
            )}
        </>
    );
}
