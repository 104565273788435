import { useState, useEffect } from "react";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { Package } from "lucide-react";
import { ShipmentsTableHeader, ShipmentColumns } from "./columns";
import { DataTable } from "./data-table";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "@/core/firebase";
import dayjs from "dayjs";
import { useUserStore } from "@/core/UserStore";
import { User } from "firebase/auth";
import { updateAllShipmentsStatus } from "@/api/shipments/updateShipment";

export async function getData(user: User): Promise<ShipmentsTableHeader[]> {
    try {
        const querySnapshot = await getDocs(query(collection(db, "shipments"), where("uid", "==", user?.email)));

        updateAllShipmentsStatus(querySnapshot);

        return querySnapshot.docs
            .map((doc) => {
                try {
                    return {
                        id: doc.id ?? "N/A",
                        carrier: doc.data().serviceDetails.carrier ?? "N/A",
                        date: dayjs(doc.data().date).format("MM/DD/YYYY") ?? "N/A",
                        trackingNumber: doc.data().trackingNumber ?? "N/A",
                        customerName: doc.data().receiver[0].attentionName ?? "N/A",
                        status: doc.data().status ?? "N/A"
                    };
                } catch (error) {
                    console.error("Error processing document: ", error);
                    return null;
                }
            })
            .filter((item): item is ShipmentsTableHeader => item !== null);
    } catch (error) {
        console.error("Error fetching data: ", error);
        return [];
    }
}
