import { Address, Customer } from "@shared/ShipmentTypes";

interface ShippingAddressProps {
    title: string;
    customer: Customer;
}

export default function ShippingAddress({ title, customer }: ShippingAddressProps) {
    const { address, attentionName } = customer;
    return (
        <div className="w-full">
            <h3 className="mb-2 text-lg font-semibold">{title}</h3>
            <div className="space-y-1 text-gray-500">
                <p>{attentionName}</p>
                <p>{address.street}</p>
                {address.line2 && <p>{address.line2}</p>}
                <p>{`${address.city}, ${address.stateCode} ${address.postalCode}`}</p>
            </div>
        </div>
    );
}
