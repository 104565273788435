import { create, StateCreator } from "zustand";
import { ShipperSlice, ReceiverSlice, ShipmentDetailsSlice, ShipmentSlice, ServiceSlice, PickupSlice, CustomsSlice, initialForm } from "@/types/ShipmentFormStoreTypes";
import { CustomsDetails, PickupDetails, ServiceDetails, Shipment, ShipmentDetails } from "@shared/ShipmentTypes";

const createShipperSlice: StateCreator<ShipperSlice & ShipmentSlice, [], [], ShipperSlice> = (set) => {
    return {
        shipper: {
            phoneNumber: "",
            companyName: "",
            attentionName: "",
            email: "",
            address: {
                street: "",
                city: "",
                postalCode: "",
                stateCode: "",
                countryCode: ""
            }
        },
        addShipper: (data) => {
            set((state) => ({ shipper: data, shipment: { ...state.shipment, shipper: data } }));
        }
    };
};

const createReceiverSlice: StateCreator<ReceiverSlice & ShipmentSlice, [], [], ReceiverSlice> = (set) => {
    return {
        receiver: {
            phoneNumber: "",
            companyName: "",
            attentionName: "",
            email: "",
            address: {
                street: "",
                city: "",
                postalCode: "",
                stateCode: "",
                countryCode: ""
            }
        },
        addReceiver: (data) => {
            set((state) => ({ receiver: data, shipment: { ...state.shipment, receiver: [data] } }));
        }
    };
};

const createShipmentDetailsSlice: StateCreator<ShipmentDetailsSlice & ShipmentSlice, [], [], ShipmentDetailsSlice> = (set) => {
    return {
        shipmentDetails: {} as ShipmentDetails,
        addShipmentDetails: (data) => {
            set((state) => ({ shipmentDetails: data, shipment: { ...state.shipment, shipmentDetails: data } }));
        }
    };
};

const createServiceSlice: StateCreator<ShipmentSlice & ServiceSlice, [], [], ServiceSlice> = (set) => {
    return {
        serviceDetails: {} as ServiceDetails,
        addServiceDetails: (data) => {
            set((state) => ({ shipment: { ...state.shipment, serviceDetails: data }, serviceDetails: data }));
        },
        removeServiceDetails: () => {
            set((state) => ({ shipment: { ...state.shipment, serviceDetails: undefined }, serviceDetails: undefined }));
        }
    };
};

const createShipmentSlice: StateCreator<ShipperSlice & ReceiverSlice & ShipmentDetailsSlice & ShipmentSlice & ServiceSlice & PickupSlice & CustomsSlice, [], [], ShipmentSlice> = (set) => {
    return {
        shipment: {} as Shipment,
        addShipment: (data) => {
            set({ shipment: data });
        },
        editData: (data) => {
            set((state) => ({ shipment: { ...state.shipment, ...data } }));
        },
        removeShipment: () => {
            set(() => ({ ...initialForm }));
        }
    };
};

const createPickupSlice: StateCreator<PickupSlice & ShipmentSlice, [], [], PickupSlice> = (set) => {
    return {
        pickupDetails: {} as PickupDetails,
        addPickupDetails: (data) => {
            set((state) => ({ shipment: { ...state.shipment, pickupDetails: data }, pickupDetails: data }));
        },
        removePickupDetails: () => {
            set((state) => ({ shipment: { ...state.shipment, pickupDetails: {} as PickupDetails }, pickupDetails: {} as PickupDetails }));
        }
    };
};

const createCustomsSlice: StateCreator<CustomsSlice & ShipmentSlice, [], [], CustomsSlice> = (set) => {
    return {
        customsDetails: {} as CustomsDetails,
        addCustomsDetails: (data) => {
            set((state) => ({ customsDetails: data, shipment: { ...state.shipment, customsDetails: data } }));
        },
        removeCustomsDetails: () => {
            set((state) => ({ shipment: { ...state.shipment, customsDetails: undefined }, customsDetails: undefined }));
        }
    };
};

export const useFormStore = create<ShipperSlice & ReceiverSlice & ShipmentDetailsSlice & ShipmentSlice & ServiceSlice & PickupSlice & CustomsSlice>()(
    // persist(
    //     (...a) => ({
    //         ...createShipmentSlice(...a),
    //         ...createShipperSlice(...a),
    //         ...createReceiverSlice(...a),
    //         ...createShipmentDetailsSlice(...a),
    //         ...createServiceSlice(...a),
    //         ...createPickupSlice(...a),
    //         ...createCustomsSlice(...a)
    //     }),
    //     {
    //         name: "shipment-store",
    //         storage: createJSONStorage(() => localStorage)
    //     }
    // )
    (...a) => ({
        ...createShipmentSlice(...a),
        ...createShipperSlice(...a),
        ...createReceiverSlice(...a),
        ...createShipmentDetailsSlice(...a),
        ...createServiceSlice(...a),
        ...createPickupSlice(...a),
        ...createCustomsSlice(...a)
    })
);
