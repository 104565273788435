import { Select as SelectField, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { get, useFormContext } from "react-hook-form";
import { ReactNode, useEffect } from "react";

export interface SelectItem {
    label: string;
    name: string;
}

export interface SelectProps {
    label?: string | ReactNode | (string & ReactNode);
    name: string;
    defaultValue?: string;
    placeholder?: string;
    onChange?: (value?: any) => void;
    SelectItemList: Array<SelectItem>;
    className?: string;
}

export default function Select({ label, name, defaultValue, placeholder, onChange, SelectItemList, className }: SelectProps) {
    const {
        formState: { errors },
        setValue
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    const changeValue = (value: string) => {
        setValue(name, value);
    };

    // console.log(defaultValue);

    defaultValue &&
        useEffect(() => {
            changeValue(defaultValue);
        }, []);

    return (
        <div className={"grid gap-2 " + className}>
            {label && <Label htmlFor={name}>{label}</Label>}
            {/* <Input {...register(name)} id={name} {...props} /> */}
            <SelectField
                defaultValue={defaultValue}
                onValueChange={(value) => {
                    changeValue(value);
                    {
                        onChange && onChange(value || undefined);
                    }
                }}>
                <SelectTrigger className={errorMessage !== undefined ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"} name={name} id={name}>
                    <SelectValue placeholder={placeholder} />
                </SelectTrigger>
                <SelectContent>
                    {SelectItemList.map((item: SelectItem, index: number) => (
                        <SelectItem value={item.name} key={index}>
                            {item.label}
                        </SelectItem>
                    ))}
                </SelectContent>
            </SelectField>
            {errorMessage !== undefined ? (
                <p className="text-xs text-red-500" role="error">
                    {errorMessage}
                </p>
            ) : (
                <p className="block text-xs text-red-500"> </p>
            )}
        </div>
    );
}
