import { db } from "@/core/firebase";
import { collection, getDocs, limit, orderBy, query, where, doc, getDoc, onSnapshot } from "firebase/firestore";
import { UserData, Partnerships } from "@shared/UserTypes";

export const getPartnershipByID = async (id: string) => {
    try {
        const partnerships = await getDoc(doc(db, "partnerships", id));
        console.log(partnerships.data());
        if (partnerships.exists()) {
            return partnerships.data() as Partnerships;
        }
    } catch (error) {
        console.error(error);
    }
};
