import { Card, CardContent, CardHeader } from "@/components/ui/card";
// import Select from "@/components/composite/Select";
import AddressForm, { AddressInfo, BusinessInfo } from "@/components/composite/AddressForm";

import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { useStepper } from "@/components/ui/stepper";

import { customerSchema } from "@/schema/ShipmentSchema";

import { FormFooter } from "../CreateShipmentPage";
import { useFormStore } from "@/core/ShipmentFormStore";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { useContext } from "react";
import CustomsDetails from "./CustomsDetails";
import Form from "@/components/composite/Form";

import { useFormSteps } from "@/context/FormStepContext";
import { Button } from "@/components/ui/button";
import Checkbox from "@/components/composite/Inputs/Checkbox";
import { useSaveAddressStore } from "@/core/SaveAddress";

export default function ReceiverForm() {
    const { setFormSteps, setSteps, steps, FormSteps } = useFormSteps();

    const formValues = useFormStore.getState().receiver;

    const methods = useForm<z.infer<typeof customerSchema>>({
        resolver: zodResolver(customerSchema),
        mode: "all",
        reValidateMode: "onChange",
        defaultValues: formValues
    });

    const { prevStep, nextStep } = useStepper();

    const goPrev = () => {
        const data = methods.getValues();
        useFormStore.getState().addReceiver(data);
        prevStep();
    };

    const onSubmit = (data: z.infer<typeof customerSchema>) => {
        console.log(data);
        useFormStore.getState().addReceiver(data);

        nextStep();
    };

    const handleSave = async (value: boolean) => {
        // console.log(value);
        useSaveAddressStore.getState().setSaveReceiver(value);
    };

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Receiver Address</FormTitle>
            </CardHeader>
            <CardContent className="p-4">
                <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                    <AddressForm />
                    <div>
                        <Checkbox name="saveAddress" label="Save this address in address book" onChange={handleSave} />
                    </div>
                    <div className="flex gap-6">
                        <Button type="button" onClick={goPrev} variant="outline" className="flex-grow">
                            Back
                        </Button>

                        <Button variant="default" type="submit" className="flex-grow">
                            Next
                        </Button>
                    </div>
                </Form>
            </CardContent>
        </Card>
    );
}
