import { Shipment, Customer, ShipmentDetails, PickupDetails, ServiceDetails, CustomsDetails } from "@shared/ShipmentTypes";

export const initialForm = {
    shipment: {} as Shipment,
    shipper: {} as Customer,
    receiver: {} as Customer,
    shipmentDetails: {} as ShipmentDetails,
    customsDetails: {} as CustomsDetails,
    pickupDetails: {} as PickupDetails
};

type ShipperSlice = {
    shipper: Customer;
    addShipper: (data: Customer) => void;
};

type ReceiverSlice = {
    receiver: Customer;
    addReceiver: (data: Customer) => void;
};

type ShipmentDetailsSlice = {
    shipmentDetails: ShipmentDetails;
    addShipmentDetails: (data: ShipmentDetails) => void;
};

type ShipmentSlice = {
    shipment: Shipment;
    addShipment: (data: Shipment) => void;
    editData: (data: object) => void;
    removeShipment: () => void;
};

type ServiceSlice = {
    serviceDetails: ServiceDetails;
    addServiceDetails: (data: ServiceDetails) => void;
    removeServiceDetails: () => void;
};

type PickupSlice = {
    pickupDetails: PickupDetails;
    addPickupDetails: (data: PickupDetails) => void;
    removePickupDetails: () => void;
};

type CustomsSlice = {
    customsDetails: CustomsDetails;
    addCustomsDetails: (data: CustomsDetails) => void;
    removeCustomsDetails: () => void;
};

type ShipmentData = {
    cost: number;
    uid: string;
};

export type { ShipperSlice, ReceiverSlice, ShipmentDetailsSlice, ShipmentSlice, ServiceSlice, PickupSlice, CustomsSlice, ShipmentData };
