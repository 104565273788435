import { useUserStore } from "@/core/UserStore";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ProtectedRoute({ children }: { children: ReactNode }) {
    const user = useUserStore((state) => state.user);
    const isLoggedIn = useUserStore((state) => state.isLoggedIn);
    // const loading = useUserStore((state) => state.loading);

    const navigate = useNavigate();
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate("/login", { replace: true });
        }
    }, [user]);
    return <>{children}</>;
}
