import { db } from "@/core/firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { Carrier, UserData } from "@shared/UserTypes";

export const updateUserInfo = async (email: string | undefined | null, updatedFields: Partial<UserData>) => {
    if (email === null || email === undefined) {
        console.error("User is signed out.");
        return null;
    }

    const userRef = doc(db, "users", email);
    await updateDoc(userRef, updatedFields);
};

export const updateUserCarriers = async (email: string | undefined | null, carriers: Record<string, Carrier>) => {
    if (email === null || email === undefined) {
        console.error("User is signed out.");
        return null;
    }

    const userRef = doc(db, "users", email);
    await setDoc(userRef, { activeCarriers: carriers }, { merge: true });
    return <UserData>(await getDoc(doc(db, "users", email))).data();
    // await updateDoc(userRef, { activeCarriers: carriers });
};
