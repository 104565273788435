// import Button from "@/components/composite/Buttons/LoadingButton";
import { Button } from "@/components/ui/button";
import Form from "@/components/composite/Form";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { CardContent, Card, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import Input from "@/components/composite/Inputs/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { LineChart } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { z } from "zod";
import OrderDetails from "@/components/composite/OrderDetails";
import { Shipment } from "@shared/ShipmentTypes";
import { getShipmentByID } from "@/api/shipments/getShipment";
import { trackShipmentBackend } from "@/api/shipments/updateShipment";
import { Status, statusIconMapping, statusIconMappingBig, statusStyleMapping } from "@/lib/statusMapping";
import { Step, StepItem, Stepper, useStepper } from "@/components/ui/stepper";

const schema = z.object({
    // trackingNumber: z.string()
    // shipmentID: z.string()
    input: z.string()
});

const statusSteps = [{ label: "Created" }, { label: "Picked up" }, { label: "In-Transit" }, { label: "Delivered" }] satisfies StepItem[];

export default function TrackingPage() {
    const [loading, setLoading] = useState(false);

    const [shipment, setShipment] = useState<Shipment | undefined>(undefined);
    const [statusList, setStatusList] = useState<Status[] | undefined>(undefined);
    // const [currentStatusStep, setCurrentStateStep] = useState<number>(0);

    const methods = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema)
    });

    const { setStep, currentStep, nextStep, resetSteps } = useStepper();

    const handleTrackRequest = async (data: z.infer<typeof schema>) => {
        console.log(data);

        if (data.input.startsWith("SNAP-")) {
            const shipmentData = await getShipmentByID(data.input);
            if (shipmentData) {
                setShipment(shipmentData);
                if (shipmentData.trackingNumber) {
                    const statusListResponse = await trackShipmentBackend(shipmentData);
                    setStatusList(statusListResponse);
                    console.log(statusListResponse);
                    console.log(statusListResponse[0]);
                    // resetSteps();
                    // if (statusListResponse[0] == "Label Created") {
                    //     setStep(1);
                    // } else if (statusListResponse[0] == "Delivered to Carrier Access Point" || statusListResponse[0] == "Shipment Picked Up by Driver") {
                    //     setStep(2);
                    // } else if (statusListResponse[0] == "In-Transit") {
                    //     setStep(3);
                    // } else if (statusListResponse[0] == "Delivered") {
                    //     setStep(4);
                    //     console.log("this works");
                    // } else {
                    //     setStep(0);
                    // }
                }
            }
        } else {
            // * add logic to call carrier
        }
    };

    // console.log(inita);

    return (
        <>
            {/* <PageTitle>
                <LineChart />
                Tracking Page
            </PageTitle> */}
            <div className="flex flex-col gap-4">
                <Card>
                    <CardHeader>
                        <CardTitle>Track your shipment</CardTitle>
                        <CardDescription>Type in your Shipment ID to the page</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Form className="grid gap-4" methods={methods} onSubmit={handleTrackRequest}>
                            <Input placeholder="Enter shipment ID" name="input" />
                            {/*  or tracking number */}
                            <Button>Track Shipment</Button>
                        </Form>
                    </CardContent>
                </Card>
                <div className="flex gap-4">
                    {shipment !== undefined && (
                        <Card className="grid h-fit p-6">
                            {/* <Stepper initialStep={0} steps={statusSteps} orientation="vertical">
                                {statusSteps.map(({ label }, index) => {
                                    return <Step key={label} label={label} />;
                                })}
                            </Stepper> */}

                            {statusList &&
                                statusList.map((status, index) => {
                                    if (index == 0) {
                                        return (
                                            <>
                                                <div className="grid gap-2">
                                                    <CurrentStatus status={status} />

                                                    {statusList.length > 1 && <h2 className="mb-2 text-lg">Recent Updates</h2>}
                                                </div>
                                            </>
                                        );
                                    } else {
                                        return (
                                            <p className={"flex items-center " + statusStyleMapping[status]} key={index}>
                                                {statusIconMapping[status]}
                                                {status}
                                            </p>
                                        );
                                    }
                                })}
                        </Card>
                    )}
                    {shipment !== undefined && <OrderDetails type="details" shipmentDetails={shipment} />}
                </div>
            </div>
        </>
    );
}

function CurrentStatus({ status }: { status: Status }) {
    return (
        <>
            <div className="from-brand-900 to-brand-400 grid h-32 gap-2 rounded-md bg-gradient-to-b p-4 text-white">
                <div className="h-10 w-10">{statusIconMappingBig[status]}</div>

                {status}
            </div>
        </>
    );
}
