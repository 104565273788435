import { ColumnDef } from "@tanstack/react-table";
import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { MoreHorizontal, ChevronsUpDown, Copy } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import { statusIconMapping, statusStyleMapping, Status } from "@/lib/statusMapping";
import { trackShipment, voidShipment } from "@/api/shipments/updateShipment";
import { toast } from "@/components/ui/use-toast";
import { cancelPickup, removePickupFromShipment, updatePickupStatus } from "@/api/pickups/updatePickups";
import { createRefund } from "@/api/payments/createRefund";
import { getPickupByID, getPickupByShipmentID } from "@/api/pickups/getPickup";
import { getShipmentByID } from "@/api/shipments/getShipment";
import { Shipment } from "@shared/ShipmentTypes";
import { PickupData } from "@shared/PickupTypes";
import { useState } from "react";

export type ShipmentsTableHeader = {
    id: string;
    carrier: string;
    customerName: string;
    status: Status;
    trackingNumber: string;
    date: string;
};

/**
 * Renders a cell with copyable content in a table column.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Object} params.cell - The cell object containing the value to be displayed and copied.
 *
 * @returns {JSX.Element} A JSX element representing the copyable column cell.
 */
const copyableColumn = ({ cell }) => {
    const [copy, setCopy] = useState(false);

    return (
        <div className="flex items-center" onMouseEnter={() => setCopy(true)} onMouseLeave={() => setCopy(false)}>
            <span>{cell.getValue()}</span>
            <Copy
                className={`ml-2 h-3 w-3 text-gray-500 ${copy ? "hover:text-primary" : "invisible"}`}
                onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(cell.getValue() as string);
                    toast({
                        description: `${cell.getValue()} copied to clipboard.`
                    });
                }}
            />
        </div>
    );
};

export const columns: ColumnDef<ShipmentsTableHeader>[] = [
    // * not needed rn, possibly useful for future
    // {
    //     id: "select",
    //     header: ({ table }) => (
    //         <Checkbox
    //             checked={table.getIsAllPageRowsSelected() ? true : table.getIsSomePageRowsSelected() ? "indeterminate" : false}
    //             onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
    //             aria-label="Select all"
    //         />
    //     ),
    //     cell: ({ row }) => <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />,
    //     enableSorting: false,
    //     enableHiding: false
    // },
    {
        accessorKey: "id",
        header: "ID",
        cell: ({ cell }) => copyableColumn({ cell })
    },
    {
        accessorKey: "carrier",
        header: ({ column }) => {
            return (
                <Button className="ml-[-1rem]" variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                    Carrier
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ row }) => {
            return (
                <>
                    <Badge className="bg-gray-700">{row.original.carrier}</Badge>
                </>
            );
        }
    },
    {
        accessorKey: "customerName",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Name
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return <div>{row.original.customerName}</div>;
        }
    },
    {
        accessorKey: "date",
        header: ({ column }) => {
            return (
                <div className="ml-[-0.25rem]">
                    <Button variant="ghost" className="data-[state=open]:bg-accent -ml-3 h-8" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Date
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        sortingFn: "datetime",
        cell: ({ row }) => {
            return <div>{row.original.date}</div>;
        }
    },
    {
        accessorKey: "status",
        header: ({ column }) => {
            return (
                <div className="ml-[-1rem]">
                    <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
                        Status
                        <ChevronsUpDown className="ml-2 h-4 w-4" />
                    </Button>
                </div>
            );
        },
        cell: ({ row }) => {
            return (
                <div className={"flex items-center " + statusStyleMapping[row.original.status]}>
                    {statusIconMapping[row.original.status]}
                    {row.original.status}
                </div>
            );
        }
    },

    {
        accessorKey: "trackingNumber",
        header: ({ column }) => {
            return (
                <Button
                    className="ml-[-1rem]"
                    variant="ghost"
                    onClick={(e) => {
                        column.toggleSorting(column.getIsSorted() === "asc");
                    }}>
                    Tracking #
                    <ChevronsUpDown className="ml-2 h-4 w-4" />
                </Button>
            );
        },
        cell: ({ cell }) => copyableColumn({ cell })
    },
    {
        id: "actions",
        cell: ({ row }) => {
            const navigate = useNavigate();
            const [resolved, setResolved] = useState(row.original.status == "Cancelled");

            return (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Actions</span>
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/shipments/id/${row.original.id}`);
                            }}>
                            View details
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                trackShipment(row.getValue("carrier"), row.getValue("trackingNumber"));
                            }}>
                            Track
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            disabled={resolved}
                            onClick={(e) => {
                                e.stopPropagation();
                                getShipmentByID(row.original.id)
                                    .then((shipment: Shipment) => {
                                        if (shipment.pickupDetails.pickupType === "pickup" && shipment.pickupDetails.id) {
                                            getPickupByID(shipment.pickupDetails.id)
                                                .then((pickupData: PickupData) => {
                                                    console.log(pickupData);
                                                })
                                                .catch((error) => {
                                                    throw error;
                                                });
                                        }
                                        voidShipment(shipment.serviceDetails?.carrier, shipment.trackingNumber, shipment.id)
                                            .then(async () => {
                                                createRefund(shipment.paymentSessionID)
                                                    .then(() => {
                                                        toast({
                                                            title: "Success!",
                                                            description: "Shipment successfully voided. Your card has been refunded the original amount charged.",
                                                            className: "bg-success-400"
                                                        });
                                                    })
                                                    .catch((e) => {
                                                        toast({
                                                            title: "Unable to process refund",
                                                            description: e.message,
                                                            className: "bg-error-400"
                                                        });
                                                    });
                                                if (shipment.pickupDetails.pickupType == "pickup") {
                                                    const pickupData = await getPickupByShipmentID(shipment.id);
                                                    if (pickupData) {
                                                        cancelPickup(pickupData)
                                                            .then(async () => {
                                                                await updatePickupStatus(pickupData.id, shipment.id, { status: "cancelled" });
                                                                await removePickupFromShipment(shipment.id);
                                                                toast({ title: "Pickup Cancelled", description: "Please schedule another pickup or drop off your shipment" });
                                                            })
                                                            .catch((e) => {
                                                                console.error(e);
                                                                toast({ title: "Pickup not Cancelled", description: "Please try again", variant: "destructive" });
                                                            });
                                                    }
                                                }

                                                setResolved(true);
                                            })
                                            .catch((e) => {
                                                toast({
                                                    title: "Unable to void shipment",
                                                    description: e.message,
                                                    className: "bg-error-400"
                                                });
                                            });
                                    })
                                    .catch((error) => {
                                        toast({
                                            variant: "destructive",
                                            title: "Error finding shipment."
                                        });
                                        console.error("Shipment does not exist.");
                                    });
                            }}>
                            Void
                        </DropdownMenuItem>
                        <DropdownMenuItem
                            onClick={(e) => {
                                e.stopPropagation();
                                getShipmentByID(row.original.id)
                                    .then((shipment: Shipment) => {
                                        if (shipment.labelURL) {
                                            const downloadLink = document.createElement("a");
                                            downloadLink.href = shipment.labelURL;
                                            downloadLink.download;
                                            downloadLink.target = "_blank";
                                            downloadLink.click();
                                        } else {
                                            toast({
                                                variant: "destructive",
                                                title: "Unable to find download URL."
                                            });
                                        }
                                    })
                                    .catch((error) => {
                                        toast({
                                            variant: "destructive",
                                            title: "Error finding shipment."
                                        });
                                        console.error("Shipment does not exist.");
                                    });
                            }}>
                            Download label
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            );
        }
    }
];
