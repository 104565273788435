import { createContext, useContext, useEffect, useState } from "react";

export const IndexContext = createContext<number>(0);

export function UseIndexContext() {
    const index = useContext(IndexContext);

    if (index === undefined) {
        throw new Error("index must be used to with a indexContext");
    }

    return index;
}
