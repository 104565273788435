import { getAllPickups } from "@/api/pickups/getPickup";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useUserStore } from "@/core/UserStore";
import { useEffect, useState } from "react";
import { PickupData } from "@shared/PickupTypes";
import { useFormStore } from "@/core/ShipmentFormStore";
import _, { pick } from "lodash";
import dayjs from "dayjs";
import { Button } from "@/components/ui/button";
import { get, useFormContext } from "react-hook-form";
import { Shipment } from "@shared/ShipmentTypes";

const PickupLocation = {
    frontDoor: "Front door",
    backEntrance: "Back entrance",
    mainLobby: "Main lobby",
    rearEntrance: "Rear entrance",
    sideEntrance: "Side entrance",
    receptionDesk: "Reception desk",
    mailroom: "Mailroom",
    conferenceRoom: "Conference room",
    employeeEntrance: "Employee entrance",
    loadingDock: "Loading dock",
    securityDesk: "Security desk",
    visitorCenter: "Visitor center",
    stairwell: "Stairwell",
    elevatorLobby: "Elevator lobby",
    warehouseEntrance: "Warehouse entrance",
    garageEntrance: "Garage entrance"
};

interface UseExistingPickup {
    shipment: Shipment;
    selectedPickup: string | undefined;
    setSelectedPickup: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export default function UseExistingPickup({ shipment, selectedPickup, setSelectedPickup }: UseExistingPickup) {
    const userData = useUserStore((state) => state.userData);

    // useEffect(() => {
    //     console.log("selected", selectedPickup);
    // }, [selectedPickup]);

    const shipperAddress = shipment.shipper;
    const carrier = shipment.serviceDetails?.carrier;

    const [pickupList, setPickupList] = useState<(PickupData | undefined)[] | null>([]);
    useEffect(() => {
        if (userData) {
            getAllPickups(userData.email)
                .then((response) => {
                    const pickups = response
                        .map((pickup) => {
                            if (_.isEqual(pickup.shipper, shipperAddress)) {
                                if (pickup.status !== "cancelled" && pickup.carrier === carrier) {
                                    return pickup;
                                }
                            }
                        })
                        .filter((pickup) => pickup);
                    console.log(pickups);
                    if (pickups.length <= 0) {
                        throw "No Pickups available";
                    }
                    setPickupList(pickups);
                })
                .catch((e) => {
                    console.error(e);
                    setPickupList(null);
                });
        }
    }, [userData]);

    return (
        <Card className="">
            <CardHeader className="p-4">
                <FormTitle>Schedule Pickup</FormTitle>
            </CardHeader>
            <CardContent className="grid h-96 grid-cols-2 gap-6 overflow-scroll p-4">
                {pickupList ? (
                    pickupList.length > 0 ? (
                        pickupList.map((pickup) => {
                            if (pickup) {
                                return <PickupCard pickupData={pickup} selectedPickup={selectedPickup} setSelectedPickup={setSelectedPickup} key={pickup.id} />;
                            }
                        })
                    ) : (
                        <Skeleton className="col-span-2 h-10 w-full" />
                    )
                ) : (
                    "There is no pickups booked"
                )}
            </CardContent>
        </Card>
    );
}

interface PickupCard {
    pickupData: PickupData;
    selectedPickup?: string;
    setSelectedPickup?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const PickupCard = ({ pickupData, selectedPickup, setSelectedPickup }: PickupCard) => {
    const handleClick = () => {
        console.log(pickupData.id);
        setSelectedPickup && setSelectedPickup(pickupData.id);
    };

    return (
        <Card className="h-fit">
            <CardHeader>
                <CardTitle className="text-sm">{pickupData.id}</CardTitle>
                <CardDescription>
                    {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("MMM D, YYYY")} from {dayjs(pickupData.pickupDetails.readyTimeStamp?.isoTimeStamp).format("h:mmA")} to{" "}
                    {dayjs(pickupData.pickupDetails.closeTimeStamp?.isoTimeStamp).format("h:mmA")}
                </CardDescription>
            </CardHeader>
            <CardContent>
                <Button type="button" variant={selectedPickup === pickupData.id ? "default" : "secondary"} className="w-full" onClick={handleClick}>
                    {selectedPickup === pickupData.id ? "Pickup selected" : "Use this pickup"}{" "}
                </Button>
            </CardContent>
            {/* <CardContent>
                {pickupData.shipper && (
                    <div>
                        <p>{pickupData.shipper.address.street}</p>
                        <p>
                            {pickupData.shipper.address.city} {pickupData.shipper.address.stateCode} {pickupData.shipper.address.postalCode}
                        </p>
                        <p>{pickupData.pickupDetails.pickupLocation && PickupLocation[pickupData.pickupDetails.pickupLocation]}</p>
                    </div>
                )}
            </CardContent> */}
        </Card>
    );
};
