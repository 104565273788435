import PageTitle from "@/components/composite/Headers/PageTitle";
import { getLatestShipment } from "@/api/shipments/getShipment";
import { useState, useEffect } from "react";
import { Shipment } from "@shared/ShipmentTypes";

import { CircleCheckBig } from "lucide-react";
import { DocumentData } from "firebase/firestore";
import { Skeleton } from "@/components/ui/skeleton";
import { useUserStore } from "@/core/UserStore";
import { useNavigate } from "react-router-dom";

export default function Contact() {
    const navigate = useNavigate();

    useEffect(() => {
        window.open("https://snapship.co/contact-us/", "_blank");
        navigate("/", { replace: true });
    });
    return <></>;
    // const [document, setDocument] = useState<DocumentData | null>(null);
    // const [shipment, setShipment] = useState<Shipment | null>(null);

    // // const phoneData = getPhoneData(phone);

    // // // useEffect(() => {
    // // //     if (user) {
    // // //         getLatestShipment(user)
    // // //             .then((response) => {
    // // //                 if (response) {
    // // //                     setDocument(response);
    // // //                     setShipment({
    // // //                         shipper: response.shipper,
    // // //                         receiver: response.receiver,
    // // //                         pickupDetails: response.pickupDetails,
    // // //                         shipmentDetails: response.shipmentDetails
    // // //                     });
    // // //                 }
    // // //             })
    // // //             .catch((error) => {
    // // //                 console.log(error);
    // // //             });
    // // //     }
    // // // }, [user]);

    // // console.log(phoneData);
    // return (
    //     <>
    //         <PageTitle>
    //             <CircleCheckBig />
    //             Order {document ? document.id : <Skeleton className="h-8 w-20" />} Confirmed!
    //         </PageTitle>
    //         <div>color</div>
    //         {shipment && (
    //             <div>
    //                 <p>Shipper: {shipment.shipper.attentionName}</p>
    //                 <p>Receiver: {shipment.receiver.attentionName}</p>
    //                 {/* Render other shipment details as needed */}
    //             </div>
    //         )}
    //     </>
    // );
}
