import { Shipment } from "@shared/ShipmentTypes";

export const calculateCost = (cost: number, domestic: boolean, shipment: Shipment) => {
    // const admin = Number((cost * 0.029 + 0.3).toFixed(2));

    const taxPercentage = getTaxPercentage(shipment);
    const admin = 0;
    const tax = domestic ? Number((cost * taxPercentage).toFixed(2)) : 0;
    const total = Number((cost + tax + admin).toFixed(2));
    return { total, tax, admin, cost, taxPercentage };
};

function getTaxPercentage(shipment: Shipment) {
    if (shipment.receiver[0].address.countryCode === "CA" && shipment.receiver[0].address.stateCode === "ON") {
        return 0.13;
    }
    if (shipment.receiver[0].address.countryCode === "CA") {
        if (
            shipment.receiver[0].address.stateCode === "NS" ||
            shipment.receiver[0].address.stateCode === "PE" ||
            shipment.receiver[0].address.stateCode === "NL" ||
            shipment.receiver[0].address.stateCode === "NB"
        ) {
            return 0.15;
        }

        if (
            shipment.receiver[0].address.stateCode === "AB" ||
            shipment.receiver[0].address.stateCode === "BC" ||
            shipment.receiver[0].address.stateCode === "MB" ||
            shipment.receiver[0].address.stateCode === "NT" ||
            shipment.receiver[0].address.stateCode === "QC" ||
            shipment.receiver[0].address.stateCode === "SK" ||
            shipment.receiver[0].address.stateCode === "YT"
        ) {
            return 0.05;
        }
    }

    return 0;
}
