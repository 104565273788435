import { Address } from "@shared/ShipmentTypes";
import { useState } from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";

interface AutocompleteProps {
    address: Address;
    setAddress: React.Dispatch<React.SetStateAction<Address>>;
}

export default function AddressAutocomplete({ address, setAddress }: AutocompleteProps) {
    const handleChange = async (address) => {
        try {
            const results = await geocodeByPlaceId(address.value.place_id);
            const addressComponents = results[0].address_components;
            console.log(addressComponents);
            const streetNumberObj = addressComponents.find((component) => component.types.includes("street_number"));
            const routeObj = addressComponents.find((component) => component.types.includes("route"));
            let cityObj = addressComponents.find((component) => component.types.includes("sublocality"));

            if (!cityObj) {
                cityObj = addressComponents.find((component) => component.types.includes("locality"));
            }

            const stateObj = addressComponents.find((component) => component.types.includes("administrative_area_level_1"));
            const countryObj = addressComponents.find((component) => component.types.includes("country"));
            const postalCodeObj = addressComponents.find((component) => component.types.includes("postal_code"));

            const streetNumber = streetNumberObj ? streetNumberObj.long_name : "";
            const route = routeObj ? routeObj.long_name : "";
            const city = cityObj ? cityObj.long_name : "";
            const state = stateObj ? stateObj.short_name : "";
            const country = countryObj ? countryObj.short_name : "";
            const postalCode = postalCodeObj ? postalCodeObj.long_name : "";

            const completedAddress: Address = {
                street: [streetNumber, route].join(" "),
                city: city,
                postalCode: postalCode,
                stateCode: state,
                countryCode: country
            };

            console.log(completedAddress);

            setAddress(completedAddress);
        } catch (error) {
            console.error("Error getting address:", error);
        }
    };

    const value = [address.street, address.city, address.postalCode, address.stateCode, address.countryCode].join(" ");
    // console.log(address);
    return (
        <>
            <div>
                <GooglePlacesAutocomplete apiKey={process.env.GOOGLE_PLACES_API_KEY} selectProps={{ onChange: handleChange, placeholder: address.street ? value : "Type in Address..." }} />
            </div>
        </>
    );
}
