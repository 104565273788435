import { Banner } from "@/components/composite/Banner";
import PhoneInput from "@/components/composite/Inputs/PhoneNumber";
import { useState, ClipboardEventHandler } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z, infer } from "zod";
import Form from "@/components/composite/Form";
import { Button } from "@/components/ui/button";
import { parsePhoneNumber } from "awesome-phonenumber";

const schema = z
    .object({
        phoneNumber: z.string()
    })
    .refine((data) => {
        return parsePhoneNumber(data.phoneNumber).valid;
    });

export default function PhoneNumberTest() {
    const methods = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        mode: "all",
        reValidateMode: "onChange"
    });

    const handleSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <div>
                <Form methods={methods} onSubmit={handleSubmit}>
                    <PhoneInput />
                    <Button>Loser</Button>
                </Form>
                {/* <Banner>
                    <a href="#" className="underline">
                        Sign up here for new features
                    </a>
                </Banner> */}
                {/* <PhoneInput value={phone} onInput={handleOnChange} /> */}
                {/* {phone} */}
            </div>
        </>
    );
}
