import { ColumnDef } from "@tanstack/react-table";
import { Address, Customer, Shipment } from "@shared/ShipmentTypes";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { getAddress, removeAddress } from "@/api/addresses";
import { useState } from "react";
import { EditAddressDialog } from "./AddressDialog";

export type AddressTableHeader = Customer & Address & { uid: string };

export const columns: ColumnDef<AddressTableHeader>[] = [
    {
        accessorKey: "attentionName",
        header: "Attention Name"
    },
    {
        accessorKey: "companyName",
        header: "Company Name"
    },
    {
        accessorKey: "street",
        header: "Street Address"
    },
    {
        accessorKey: "city",
        header: "City"
    },
    {
        accessorKey: "stateCode",
        header: "Province"
    },
    {
        accessorKey: "postalCode",
        header: "Postal Code"
    },
    {
        id: "actions",
        // header: "Action"
        cell: ({ row }) => {
            const [open, setOpen] = useState(false);

            const [address, setAddress] = useState<Customer>({} as Customer);

            return (
                <>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="ghost" className="h-8 w-8 p-0" onClick={(e) => e.stopPropagation()}>
                                <span className="sr-only">Actions</span>
                                <MoreHorizontal className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            <DropdownMenuItem
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const index = parseInt(row.id);
                                    const uid = row.original.uid;
                                    console.log(index);
                                    const address = await getAddress(index, uid);
                                    if (address) {
                                        // console.log(open);
                                        // console.log(address);
                                        setAddress(address);
                                        setOpen(!open);
                                        // removeAddress(address, uid);
                                    }
                                }}>
                                Edit Address
                            </DropdownMenuItem>
                            <DropdownMenuItem
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    const index = parseInt(row.id);
                                    const uid = row.original.uid;
                                    console.log(index);
                                    const address = await getAddress(index, uid);
                                    if (address) {
                                        console.log(address);
                                        // const address = row.original as Customer;
                                        removeAddress(address, uid);
                                    }
                                }}>
                                Remove Address
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <EditAddressDialog open={open} setOpen={setOpen} defaultValues={address} />
                </>
            );
        }
    }
];
