import { Input as InputField } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChangeEvent, ChangeEventHandler, ComponentPropsWithoutRef, ReactNode, useCallback, useEffect } from "react";
import { get, useFormContext, useFormState } from "react-hook-form";

interface InputProps extends ComponentPropsWithoutRef<"input"> {
    label?: string | ReactNode | (string & ReactNode);
    name: string;
    type?: "text" | "number" | "password" | "email" | "time";
    placeholder?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    disabled?: boolean;
    className?: string;
    value?: string | number;
    min?: number;
}

export default function Input({ label, name, type, placeholder, onChange, disabled, value, className, min, ...rest }: InputProps) {
    const {
        register,
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
        // console.log(errors);
        setValue(name, event.target.value);
    };

    // useEffect(() => {
    //     console.log(getValues(name));
    // }, [getValues(name)]);

    return (
        <div className={`grid gap-2 ${className}`}>
            {label && <Label htmlFor={name}>{label}</Label>}
            <InputField
                {...register(name, { valueAsNumber: type === "number" })}
                id={name}
                type={type}
                placeholder={placeholder}
                onChange={(value) => {
                    changeValue(value);
                    {
                        onChange && onChange(value || undefined);
                    }
                }}
                disabled={disabled}
                value={value}
                className={`${errorMessage !== undefined ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"}`}
                step={type === "number" ? "any" : undefined}
                onFocus={(e) =>
                    e.target.addEventListener(
                        "wheel",
                        function (e) {
                            e.preventDefault();
                        },
                        { passive: false }
                    )
                }
                min={type === "number" ? min : undefined}
                {...rest}
            />
            {errorMessage !== undefined ? (
                <p role="error" className="text-error-500 text-xs">
                    {errorMessage}
                </p>
            ) : (
                <p className="block text-xs text-red-500"> </p>
            )}
        </div>
    );
}
