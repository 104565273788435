import { db } from "@/core/firebase";
import { UserData } from "@shared/UserTypes";
import { collection, getDoc, doc } from "firebase/firestore";

export const getUserbyEmail = async (email: string | undefined | null) => {
    if (email === null || email === undefined) {
        console.error("User is signed out.");
        return null;
    }

    const userRef = doc(db, "users", email);
    const user = await getDoc(userRef);
    if (user.exists()) {
        return <UserData>user.data();
    } else {
        console.log("No user found");
        return null;
    }
};
