import EmptyBox from "@/assets/misc/ErrorPageImage.svg";

export default function ErrorPage() {
    return (
        <div className="flex min-h-full items-center justify-center overflow-hidden">
            <div className="mx-auto max-w-lg p-4 text-center">
                <img src={EmptyBox} alt="Empty box" />
                <div style={{ maxHeight: "50vh", top: "-5rem", position: "relative", zIndex: 1 }}>
                    <h1 className="text-5xl font-bold">Error 404</h1>
                    <p className="mt-4 text-gray-500">The page you requested could not be found.</p>
                </div>
            </div>
        </div>
    );
}
