import { createContext, useContext } from "react";

type carrierDialog = { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> };
export const carrierDialog = createContext<carrierDialog>({} as carrierDialog);

export function useCarrierDialog() {
    const dialog = useContext(carrierDialog);

    if (dialog === undefined) {
        throw new Error("Carrier Dialog must be used to with a Carrier Dialog Provider");
    }

    return dialog;
}
