import { PickupData } from "@shared/PickupTypes";
import { generatePickupID } from "../generateID";
import { db } from "@/core/firebase";
import { doc, setDoc } from "firebase/firestore";

export const storePickupData = async (payload: PickupData, shipmentID: string) => {
    const id = await generatePickupID();
    try {
        await setDoc(doc(db, "pickups", id), { ...payload, id: id }, { merge: true });
        console.log("created pickup");
        await setDoc(doc(db, "shipments", shipmentID), { pickupDetails: { id: id } }, { merge: true });
    } catch (error) {
        throw new Error(`Failed to update pickup: ${error.message}`);
    }
};
