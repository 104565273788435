import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import logo from "@/assets/logos/SnapShipLogoNEW.svg";

import { auth, db } from "@/core/firebase";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Image from "@/assets/misc/GetQuotePage.svg";
import Form from "@/components/composite/Form";

import Input from "@/components/composite/Inputs/Input";
import { signInWithCustomToken } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import axios from "axios";
import Separator from "@/components/composite/Separator";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { useState } from "react";

const schema = z.object({
    token: z.string().optional(),
    email: z.string().optional()
});

export default function ResetPassword() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema)
    });

    const handleSubmit = async (data: z.infer<typeof schema>) => {
        setIsLoading(true);
        let token = "";
        if (data.token) {
            token = data.token;
        } else {
            token = (await axios.get(`${import.meta.env.VITE_API_URL}/user/${data.email}`)).data;
        }
        signInWithCustomToken(auth, token)
            .then(async (userCredential) => {
                const user = userCredential.user;
                console.log(user);
                // useAuthStore.getState().addAccessToken(token);
                navigate("/", { replace: true });
                // sendRequest(token);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);

                let message = `${errorCode} ${errorMessage}`;
                methods.setError("root", {
                    message: message
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="grid h-full w-full place-content-center gap-4">
                <img src={logo} className="h-10 place-self-center object-cover" />
                <Card className="mx-auto max-w-sm border-none shadow-none">
                    <CardHeader>
                        <CardTitle className="text-2xl">{"Admin Login"}</CardTitle>
                        <CardDescription>Enter email or token below to login into account</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Form methods={methods} onSubmit={handleSubmit} className="grid gap-6">
                            <Input name="token" label="Use Generated Token" />
                            <Separator text="or" />
                            <Input name="email" label="Type in email" />
                            <LoadingButton isLoading={isLoading} className="w-full" type="submit">
                                Login
                            </LoadingButton>
                        </Form>
                    </CardContent>
                </Card>
            </div>
        </>
    );
}
