import { Input as InputField } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface InputProps {
    label?: string | ReactNode | (string & ReactNode);
    name: string;
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    date: Date | undefined;
    setDate: React.Dispatch<React.SetStateAction<Date>>;
    defaultValue?: string | number;
    onChange?: Function;
}

export default function TimePicker({ label, name, placeholder, disabled, value, date, setDate, onChange }: InputProps) {
    const {
        register,
        formState: { errors },
        setValue,
        getValues
    } = useFormContext();

    let defaultValue = date && dayjs(date).format("HH:mm");

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = dayjs(event.target.value, "HH:mm");
        setDate(value.toDate());
        onChange && onChange(value.toDate());
    };

    const errorMessage = get(errors, name)?.message;
    return (
        <div className="grid gap-2">
            {label && <Label htmlFor={name}>{label}</Label>}
            <InputField
                {...register(name)}
                defaultValue={defaultValue}
                id={name}
                type={"time"}
                placeholder={placeholder}
                onChange={handleChange}
                disabled={disabled}
                value={value}
                className={errorMessage !== undefined ? "border-error-500 focus-visible:ring-0" : "ring-brand-500"}
                // step="900"
            />
            {errorMessage !== undefined ? <p className="text-error-500 text-xs">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
}
