import axios from "axios";

export const generateID = async () => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/generate-id`);
    return response.data;
};

export const generatePickupID = async () => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/generate-pickup-id`);
    return response.data;
};
