import axios from "axios";

export const createRefund = async (id: string | undefined | null) => {
    if (id) {
        if (id.startsWith("ii")) {
            try {
                const response = await axios.put(`${import.meta.env.VITE_API_URL}/Stripe/invoice-item/${id}`);
                console.log(response.data);
            } catch (e) {
                console.error(e);
            }
        }
        if (id.startsWith("pi")) {
            try {
                const response = await axios.put(`${import.meta.env.VITE_API_URL}/Stripe/refund/${id}`);
                console.log(response.data);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
