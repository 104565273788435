import { Separator } from "@/components/ui/separator";

export default function HorizontalSeparator({ text = "" }) {
    return (
        <div className="grid w-full grid-cols-[1fr_2%_1fr] items-center gap-1">
            <Separator />
            <p className="text-sm">{text}</p>
            <Separator />
        </div>
    );
}
