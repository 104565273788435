import { Separator } from "@/components/ui/separator";
import { ReactNode } from "react";

interface SectionHeaderProps {
    title: string;
    description: string;
}

export default function SectionHeader(props: SectionHeaderProps) {
    return (
        <div className="col-span-2 mb-6 space-y-6">
            <div>
                <h3 className="text-lg font-medium">{props.title}</h3>
                <p className="text-muted-foreground text-sm">{props.description}</p>
            </div>
            <Separator />
        </div>
    );
}
