import { useState, useEffect } from "react";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { Package } from "lucide-react";
import { ShipmentsTableHeader, columns } from "./columns";
import { DataTable } from "./data-table";
import { useUserStore } from "@/core/UserStore";
import { getData } from "../home/shipments/fetchData";

export default function ShipmentsLayout() {
    const [data, setData] = useState<ShipmentsTableHeader[]>([]);
    const [loading, setLoading] = useState(true);

    const user = useUserStore((state) => state.user);

    useEffect(() => {
        async function fetchData() {
            if (user) {
                const result = await getData(user);
                setData(result);
                setLoading(false);
            }
        }
        fetchData();
    }, [user]);

    return (
        <>
            <PageTitle>
                <Package />
                Shipments
            </PageTitle>
            <DataTable columns={columns} data={data} loading={loading} />
        </>
    );
}
