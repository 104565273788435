import SectionHeader from "../../../components/composite/Headers/SectionHeader";
import { Button } from "@/components/ui/button";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react";

import axios from "axios";
import { useUserStore } from "@/core/UserStore";

import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import Form from "@/components/composite/Form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { invoiceRequestSchema } from "@/schema/RequestInvoiceSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { sendInvoiceRequest } from "@/api/emails/sendInvoiceRequest";
import Input from "@/components/composite/Inputs/Input";
import Textbox from "@/components/composite/Inputs/Textbox";
import Select from "@/components/composite/Inputs/Select";

const InvoiceCycleOptions = [
    {
        name: "1",
        label: "Daily (1 day)"
    },
    {
        name: "7",
        label: "Weekly (7 days)"
    }
];

export default function PaymentsForm() {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const [open, setOpen] = useState(false);

    const userData = useUserStore((state) => state.userData);

    console.log(userData);
    const customerID = userData?.stripe;

    const handleBilling = (e: any) => {
        e.preventDefault();

        setIsLoading(true);
        axios
            .post(`${import.meta.env.VITE_API_URL}/Stripe/create-customer-portal-session/${customerID}`)
            .then((res) => {
                window.open(res.data.url, "_blank");
            })
            .catch((error) => {
                console.error("Error:", error);
                toast({
                    variant: "destructive",
                    title: "Uh oh! Something went wrong.",
                    description: "There was a problem with your request."
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const methods = useForm<z.infer<typeof invoiceRequestSchema>>({
        resolver: zodResolver(invoiceRequestSchema),
        mode: "onChange",
        defaultValues: {
            email: userData?.email,
            company: userData?.companyName
        }
    });

    const handleRequest = (data: z.infer<typeof invoiceRequestSchema>) => {
        console.log(data);
        sendInvoiceRequest(data);
        setOpen(false);
    };

    return (
        <>
            <SectionHeader title="Payments" description="Manage payment methods, billing information, and invoices."></SectionHeader>
            <div className="grid gap-4">
                <div className="flex gap-4">
                    <LoadingButton className="w-48" isLoading={isLoading} onClick={handleBilling}>
                        Manage account billing
                    </LoadingButton>
                    <Button onClick={() => setOpen(true)} disabled={userData?.invoiceCycle !== undefined} variant={userData?.invoiceCycle !== undefined ? "ghost" : "default"}>
                        {userData?.invoiceCycle !== undefined ? "Credit and Weekly Invoicing has been Enabled " : "Enable Credit and Weekly Invoicing"}
                    </Button>
                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>SnapShip Solutions Inc. Credit Terms</DialogTitle>
                                <DialogDescription>
                                    We offer select customers credit terms of up to 7 days. Invoices are issued every Monday for the previous week, and payments are due within 7 days. <br />
                                    <b>Important:</b> Failure to pay on time will result in your account being placed on hold and sent to collections without exception.
                                    <br />
                                    For questions, contact us at info@snapship.co.
                                </DialogDescription>
                            </DialogHeader>

                            <Form methods={methods} onSubmit={handleRequest}>
                                <div className="grid gap-4 py-2">
                                    {/* <Input label="Amount" type="number" name="amount" placeholder="Enter an amount" /> */}
                                    <Select SelectItemList={InvoiceCycleOptions} name="invoiceCycle" />
                                    <Textbox name="comments" label={"Additional comments (optional)"} className="resize-none" />
                                </div>
                                <DialogFooter>
                                    <Button type="submit">Submit request</Button>
                                </DialogFooter>
                            </Form>
                        </DialogContent>
                    </Dialog>
                </div>
                {/* <div className="">{userData?.partnerships && `Partnership code: ${userData?.partnerships}`}</div> */}
            </div>
        </>
    );
}
