/* -------------------------------------------------------------------------- */
/*                             External Dependency                            */
/* -------------------------------------------------------------------------- */

import React, { ReactNode, useEffect } from "react";
import { Check, ChevronsUpDown } from "lucide-react";

/* -------------------------------------------------------------------------- */
/*                             Internal Dependency                            */
/* -------------------------------------------------------------------------- */

import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";
import { countries } from "@/data/countries";

import { useDropdownStore } from "@/core/Dropdown";
import { get, useFormContext } from "react-hook-form";
import { Label } from "@/components/ui/label";

interface CountryDropdownProps {
    disabled?: boolean;
    name: string;
    label?: string | ReactNode | (string & ReactNode);
    onChange?: (selectedCountry: string | undefined) => void;
}

export type Country = (typeof countries)[number];

const CountryDropdown = ({ disabled, name, label, onChange }: CountryDropdownProps) => {
    const { countryValue, setCountryValue, openCountryDropdown, setOpenCountryDropdown, setStateValue } = useDropdownStore();
    const C = countries as Country[];

    const {
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    // console.log(name);
    useEffect(() => {
        setValue(name, countryValue);
        {
            onChange && onChange(countryValue || undefined);
        }
    }, [countryValue]);

    // const handleSelect = (currentValue: string) => {
    //     // console.log(currentValue);
    //     // setCountryValue(currentValue === lowerCase(country.name) ? currentValue : "");
    //     // setOpenCountryDropdown(false);
    // };
    return (
        <div className="grid gap-2">
            {label && <Label htmlFor={name}>{label}</Label>}

            <Popover open={openCountryDropdown} onOpenChange={setOpenCountryDropdown}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={openCountryDropdown}
                        name={name}
                        id={name}
                        // className="w-[300px] justify-between rounded-[6px] border !border-[#27272a] !bg-[#0f0f11] hover:!bg-[#0f0f11] focus:!bg-[#0f0f11] focus:!outline-none focus:!ring-2 focus:!ring-[#0f0f11] focus:!ring-offset-2 focus:!ring-offset-[#0f0f11]"
                        disabled={disabled}>
                        <span>
                            {countryValue ? (
                                <div className="flex items-end gap-2">
                                    <span>{countries.find((country) => country.iso2 === countryValue)?.emoji}</span>
                                    <span>{countries.find((country) => country.iso2 === countryValue)?.name}</span>
                                </div>
                            ) : (
                                <span>Select Country...</span>
                            )}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                </PopoverTrigger>
                {/* <PopoverContent className="w-[300px] rounded-[6px] border border-[#27272a] p-0">
                <Command>
                    <CommandInput placeholder="Search country..." />
                    <CommandEmpty>No country found.</CommandEmpty>
                    <CommandGroup>
                        <ScrollArea className="h-[300px] w-full">
                            {C.map((country) => (
                                <CommandItem
                                    key={country.id}
                                    value={country.name}
                                    onSelect={(currentValue) => {
                                        setCountryValue(currentValue === lowerCase(country.name) ? currentValue : "");
                                        setOpenCountryDropdown(false);
                                    }}
                                    className="flex cursor-pointer items-center justify-between text-xs hover:!bg-[#27272a] hover:!text-white">
                                    <div className="flex items-end gap-2">
                                        <span>{country.emoji}</span>
                                        <span className="">{country.name}</span>
                                    </div>
                                    <Check className={cn("mr-2 h-4 w-4", countryValue === lowerCase(country.name) ? "opacity-100" : "opacity-0")} />
                                </CommandItem>
                            ))}
                            <ScrollBar orientation="vertical" />
                        </ScrollArea>
                    </CommandGroup>
                </Command>
            </PopoverContent> */}
                <PopoverContent className="w-max p-0" align="start">
                    <Command>
                        <CommandInput placeholder="Search country..." />
                        <CommandList>
                            <CommandEmpty>No country found.</CommandEmpty>
                            <ScrollArea className={"[&>[data-radix-scroll-area-viewport]]:max-h-[300px]"}>
                                <CommandGroup>
                                    {C.map((country) => (
                                        <CommandItem
                                            key={country.id}
                                            value={country.name}
                                            onSelect={(currentValue: string) => {
                                                const value = currentValue === country.name ? country.iso2 : "";
                                                // console.log(value);
                                                setCountryValue(value);
                                                // setValue(name, value);
                                                setStateValue("");
                                                setOpenCountryDropdown(false);
                                            }}
                                            className="flex cursor-pointer items-center justify-between text-xs hover:!bg-[#27272a] hover:!text-white">
                                            <div className="flex items-end gap-2">
                                                <span>{country.emoji}</span>
                                                <span className="">{country.name}</span>
                                            </div>
                                            <Check className={cn("mr-2 h-4 w-4", countryValue === country.iso2 ? "opacity-100" : "opacity-0")} />
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </ScrollArea>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
            {errorMessage !== undefined ? <p className="text-error-500 text-xs">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
};

export default CountryDropdown;
