import axios from "axios";

export const createStripeCustomer = async (email: string, name: string, companyName: string) => {
    let payload = {
        email: email,
        name: name,
        companyName: companyName
    };
    const stripe = await axios.post(`${import.meta.env.VITE_API_URL}/Stripe/customer`, payload);
    console.log(stripe.data);
    return stripe.data.id;
};

export const updateStripeCustomer = async (id: string, name: string, companyName: string) => {
    let payload = {
        name: `${name} | ${companyName}`
    };
    const stripe = await axios.put(`${import.meta.env.VITE_API_URL}/Stripe/customer/${id}`, payload);
    // console.log(stripe.data);
    return stripe.data.id;
};
