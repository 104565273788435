import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { useFormContext } from "react-hook-form";
import { useEffect, useState, memo } from "react";
import { useFormStore } from "@/core/ShipmentFormStore";
import ShipmentOptions from "./ShipmentOptions";
import Input from "@/components/composite/Inputs/Input";

const TubeDetails = memo(function TubeDetails() {
    const formValues = useFormStore.getState().shipmentDetails;

    const { control, setValue, getValues } = useFormContext();

    useEffect(() => {
        setValue("deliveryFormat", "tube");
        setValue("packages.0.length", 0);
        setValue("packages.0.width", 0);
        setValue("packages.0.height", 0);
    }, []);

    return (
        <Card>
            <CardHeader className="grid grid-cols-3 items-center gap-6">
                <CardTitle className="text-base font-medium">Tube Details</CardTitle>
                <ShipmentOptions />
            </CardHeader>
            <CardContent className="">
                <Input label="Weight" name="packages.0.weight" type="number" />
            </CardContent>
        </Card>
    );
});

export default TubeDetails;
