import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useEffect } from "react";
import { get, useFormContext } from "react-hook-form";

interface option {
    name: string;
    label: string;
}

interface RadioButtonsProps {
    name: string;
    options: Array<option>;
    defaultValue?: string;
    onChange?: (value?: any) => void;
    className?: string;
}

export default function RadioButtons({ name, options, defaultValue, onChange, className }: RadioButtonsProps) {
    const {
        register,
        formState: { errors },
        setValue,
        getValues
    } = useFormContext();

    const changeValue = (value: string) => {
        // console.log("value", value);
        setValue(name, value);
        // console.log("getValues", getValues(name));
    };

    {
        defaultValue &&
            useEffect(() => {
                changeValue(defaultValue);
            }, []);
    }

    const errorMessage = get(errors, name)?.message;

    return (
        <div className={className}>
            <RadioGroup
                onValueChange={(value) => {
                    changeValue(value);
                    {
                        onChange && onChange(value || undefined);
                    }
                }}
                className={"flex gap-6"}
                defaultValue={defaultValue}
                id={name}
                {...register(name)}>
                {options.map((option: option, index: number) => (
                    <div className="flex items-center space-x-2" key={index}>
                        <RadioGroupItem value={option.name} id={option.name} />
                        <Label htmlFor={option.name}>{option.label}</Label>
                    </div>
                ))}
            </RadioGroup>
            {errorMessage !== undefined ? <p className="text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
}
