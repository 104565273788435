import { DatePicker as DateSelector } from "@/components/ui/date-picker";
import { get, useFormContext } from "react-hook-form";
import { Label } from "@/components/ui/label";
import { useState } from "react";

interface DatePickerProps {
    label?: string;
    name?: string;
    date: Date | undefined;
    setDate: React.Dispatch<React.SetStateAction<Date>>;
}

export default function DatePicker({ label, name, date, setDate }: DatePickerProps) {
    const {
        register,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    return (
        <div className="grid w-full gap-2">
            {label && <Label>{label}</Label>}
            <DateSelector date={date} setDate={setDate} />
            {errorMessage !== undefined ? <p className="text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </div>
    );
}
