import axios from "axios";
import { suppliesSchema } from "@/schema/SuppliesSchema";
import { z } from "zod";

export const sendSupplyEmail = async (data: z.infer<typeof suppliesSchema>) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/email/send-supplies`, data);
        console.log(response);
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};
