import { RatesResponse } from "@/types/ResponseTypes";
import { create } from "zustand";

type Rates = {
    rates: Array<RatesResponse>;
    addRates: (data: RatesResponse) => void;
    removeRates: () => void;
};

const useRatesStore = create<Rates>((set, get) => {
    return {
        rates: [],
        addRates: (data) => {
            set((state) => ({ rates: [...state.rates, data] }));
        },
        removeRates: () => {
            set({ rates: [] });
        }
    };
});

export default useRatesStore;
