import axios from "axios";

import { Customer } from "@shared/ShipmentTypes";

import { LocationResponse } from "@/types/ResponseTypes";

const FedExLocateCenter = async (payload: Customer) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/FedEx/locate-center`, payload);

        const locationList: any[] = response.data.output.locationDetailList;

        return locationList.map((location) => {
            return <LocationResponse>{
                distance: location.distance.value.toFixed(1),
                details: {
                    companyName: location.contactAndAddress.contact.companyName,
                    phoneNumber: location.contactAndAddress.contact.phoneNumber,
                    address: {
                        street: location.contactAndAddress.address.streetLines[0],
                        postalCode: location.contactAndAddress.address.postalCode,
                        stateCode: location.contactAndAddress.address.stateOrProvinceCode,
                        countryCode: location.contactAndAddress.address.countryCode,
                        city: location.contactAndAddress.address.city
                    }
                }
            };
        });
        // console.log(response.data);
    } catch (error) {
        console.error(error);
    }
};

// const UPSCheckForPickup = async (payload: Pickup) => {
//     try {
//         const response = await axios.post(`${import.meta.env.VITE_API_URL}/UPS/check-for-pickup`, payload);
//         console.log(response.data);
//         let status = response.data.PickupRateResponse.Response.ResponseStatus.Description;
//         return status === "Success";
//     } catch (error) {
//         console.error(error);
//     }
// };

const locateCenter = async (carrier: string, payload: Customer) => {
    console.log(payload);

    try {
        if (carrier === "FedEx") {
            return await FedExLocateCenter(payload);
        }
        // else if (carrier === "UPS") {
        //     return await UPSConfirmCost(payload);
        // } else if (carrier === "Purolator") {
        //     return await PurolatorConfirmCost(payload);
        // }
    } catch (error) {
        console.error("Error fetching locations:", error);
        throw error; // Allow the React component to handle the error
    }
};

export default locateCenter;
