import { Button } from "@/components/ui/button";
import { CreditCard, EllipsisVertical, Truck, Ban } from "lucide-react";
import carrierLogo from "@/assets/carriers/PurolatorLogo.png";
import DetailSection from "@/components/composite/OrderDetails/DetailSection";
import ShippingAddress from "@/components/composite/OrderDetails/ShippingAddress";
import BackButton from "@/components/composite/Buttons/BackButton";
import CarrierLogo from "@/components/composite/CarrierLogo";

import { useFormStore } from "@/core/ShipmentFormStore";
import getCost from "@/api/confirmCost";
import { useState, useEffect } from "react";
import { useStepper } from "@/components/ui/stepper";
import { useNavigate } from "react-router-dom";
import OrderDetails from "@/components/composite/OrderDetails";
import { auth } from "@/core/firebase";
import { createInvoiceItem, createPayment } from "@/api/payments/createPayment";
import useRatesStore from "@/core/RatesStore";
import { useUserStore } from "@/core/UserStore";
import { getDoc } from "firebase/firestore";
import { generateID } from "@/api/generateID";
import { calculateCost } from "@/lib/calculateCost";
import dayjs from "dayjs";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { addAddress } from "@/api/addresses";
import { useSaveAddressStore } from "@/core/SaveAddress";
import { useToast } from "@/components/ui/use-toast";

export default function ConfirmShipment() {
    const cost = useFormStore((state) => state.shipment.cost);
    console.log(cost);
    const [id, setID] = useState("");

    const [loading, setLoading] = useState(false);
    const userData = useUserStore((state) => state.userData);
    const editData = useFormStore((state) => state.editData);

    const shipmentDetails = useFormStore((state) => state.shipment);

    const { toast } = useToast();

    useEffect(() => {
        generateID()
            .then((response: string) => {
                setID(response);
                editData({ id: response, date: dayjs().format() });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const { prevStep, nextStep } = useStepper();

    const service = useFormStore((state) => state.serviceDetails.serviceName);

    const navigate = useNavigate();

    const handleCost = async () => {
        if (userData && cost) {
            editData({
                uid: userData.email
            });
            const response = await createPayment(cost, service, userData.stripe);
            let url = response?.data?.url;
            if (url) {
                if (useSaveAddressStore.getState().saveShipper) {
                    addAddress(shipmentDetails.shipper, userData.email);
                }
                if (useSaveAddressStore.getState().saveReceiver) {
                    shipmentDetails.receiver.forEach((address) => {
                        addAddress(address, userData.email);
                    });
                }

                useRatesStore.getState().removeRates();
                window.location.replace(url);
            } else {
                toast({
                    title: "Sorry, something went wrong with your shipment",
                    description: ""
                });
            }
        }
    };

    const handleInvoice = async () => {
        try {
            setLoading(true);
            if (userData && cost) {
                editData({
                    uid: userData.email
                });

                const status = await createInvoiceItem(cost, service, userData.stripe, userData.invoiceCycle, userData.dueDate);

                // console.log(status);

                if (status.data) {
                    if (useSaveAddressStore.getState().saveShipper) {
                        addAddress(shipmentDetails.shipper, userData.email);
                    }
                    if (useSaveAddressStore.getState().saveReceiver) {
                        shipmentDetails.receiver.forEach((address) => {
                            addAddress(address, userData.email);
                        });
                    }

                    useRatesStore.getState().removeRates();
                    navigate(`/shipment-success/${id}`);
                } else {
                    toast({
                        title: "Sorry, something went wrong with your shipment",
                        description: ""
                    });

                    setLoading(false);
                }
            }
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    // console.log(userData);

    return (
        <>
            <OrderDetails type="confirm" shipmentDetails={shipmentDetails} />
            {/* <div className="mt-6 grid w-full grid-cols-[2fr_1fr_1fr] gap-6"> */}
            <div className="mt-6 flex w-full gap-6">
                <Button type="button" onClick={prevStep} variant="outline" className="flex-grow">
                    Back
                </Button>

                {/* <Button variant="default" type="submit" className="flex-grow" disabled={useFormStore.getState().ServiceCode === undefined} onClick={confirmShipment}> */}
                <Button variant="default" type="submit" className="flex-grow" onClick={handleCost} disabled={id === ""}>
                    Pay now
                </Button>
                {userData?.invoiceCycle && (
                    <LoadingButton type="button" onClick={handleInvoice} isLoading={loading} disabled={id === ""}>
                        <CreditCard className="mr-2" />
                        Invoice Me
                    </LoadingButton>
                )}
            </div>
        </>
    );
}
