import { Step } from "react-joyride";

const Steps: Step[] = [
    {
        target: "body",
        content: (
            <div>
                <b>Welcome to SnapShip!</b> You can skip this tour at any time by pressing 'Skip' or click 'Exit' in the top right corner.
            </div>
        ),
        placement: "center"
    },
    {
        target: "#first-step",
        content: (
            <div>
                <b>Select Your Carriers:</b> Pick the carriers you want to use for your shipments and agree to the Terms and Conditions. You can toggle carriers on or off after completing this
                onboarding.
            </div>
        ),
        disableBeacon: true,
        data: {
            next: "/settings/carriers"
        },
        placement: "auto",
        disableScrolling: true
    },
    {
        target: "#second-step",
        content: (
            <div>
                <b>Order Packaging Supplies:</b> Need carrier packaging? Click here to order directly from us.
            </div>
        ),
        data: {
            next: "/create-shipment",
            previous: "/settings/carriers"
        },
        disableBeacon: true,
        disableScrolling: true,
        placement: "auto"
    },
    {
        target: "#third-step",
        content: (
            <div>
                <b>Start Shipping:</b> Once you're set up, you can begin shipping with our discounted rates!
            </div>
        ),
        data: {
            next: "/create-shipment",
            previous: "/settings/carriers"
        },
        disableBeacon: true,
        disableScrolling: true
    },
    {
        target: "body",
        content: (
            <div>
                <b>Bookmark:</b> Bookmark this page to easily return to it later.
            </div>
        ),
        placement: "center",
        data: {
            next: "/settings/carriers",
            previous: "/create-shipment"
        },
        locale: { last: "Finish" }
    }
];

export default Steps;
