import { db } from "@/core/firebase";
import { collection, getDocs, limit, orderBy, query, where, doc, getDoc, onSnapshot } from "firebase/firestore";

import { User } from "firebase/auth";
import { Shipment } from "@shared/ShipmentTypes";

export const getLatestShipment = async (user: User | null) => {
    if (user === null) {
        console.error("user is signed out");
        return null;
    }

    const shipmentRef = collection(db, "shipments");
    const queryFirestore = query(shipmentRef, where("uid", "==", user?.email), orderBy("date", "desc"), limit(1));
    const shipments = await getDocs(queryFirestore);
    if (!shipments.empty) {
        console.log(shipments.docs[0].data());
        return shipments.docs[0].data();
    } else {
        console.log("No shipments found");
        return null;
    }
};

export const getShipmentByID = async (id: string) => {
    if (id === null) {
        console.error("User is signed out.");
        return null;
    }

    const shipmentRef = doc(db, "shipments", id);
    const shipment = await getDoc(shipmentRef);
    if (shipment.exists()) {
        console.log(shipment.data());
        return shipment.data() as Shipment;
    } else {
        console.log("No shipment found");
    }
};
