import { Card, CardContent, CardHeader } from "@/components/ui/card";
import Select from "@/components/composite/Inputs/Select";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
// import { DatePicker } from "@/components/ui/date-picker";
import DatePicker from "@/components/composite/Inputs/DatePicker";
// import { toast } from "sonner"
// import { TimePicker } from "@/components/composite/TimeSelector";
import TimePicker from "@/components/composite/Inputs/TimePicker";
import dayjs from "dayjs";
import Textbox from "@/components/composite/Inputs/Textbox";

const PickupLocation = [
    { name: "frontDoor", label: "Front door" },
    { name: "backEntrance", label: "Back entrance" },
    { name: "mainLobby", label: "Main lobby" },
    { name: "rearEntrance", label: "Rear entrance" },
    { name: "sideEntrance", label: "Side entrance" },
    { name: "receptionDesk", label: "Reception desk" },
    { name: "mailroom", label: "Mailroom" },
    { name: "conferenceRoom", label: "Conference room" },
    { name: "employeeEntrance", label: "Employee entrance" },
    { name: "loadingDock", label: "Loading dock" },
    { name: "securityDesk", label: "Security desk" },
    { name: "visitorCenter", label: "Visitor center" },
    { name: "stairwell", label: "Stairwell" },
    { name: "elevatorLobby", label: "Elevator lobby" },
    { name: "warehouseEntrance", label: "Warehouse entrance" },
    { name: "garageEntrance", label: "Garage entrance" }
];

export default function CreatePickup() {
    const [isLoading, setIsLoading] = useState(false);

    const {
        setValue,
        getValues,
        handleSubmit,
        formState: { errors }
    } = useFormContext();

    const [date, setDate] = useState<Date>(dayjs(getValues("readyTimeStamp.isoTimeStamp")).toDate() || ({} as Date));
    const [readyTime, setReadyTime] = useState<Date>(dayjs(getValues("readyTimeStamp.isoTimeStamp")).toDate() || ({} as Date));
    const [closeTime, setCloseTime] = useState<Date>(dayjs(getValues("closeTimeStamp.isoTimeStamp")).toDate() || ({} as Date));

    const setDates = () => {
        const dateFormatted = dayjs(date || null);
        const readyTimeFormatted = dayjs(readyTime || null);
        const closeTimeFormatted = dayjs(closeTime || null);

        if (date && readyTime) {
            const readyTimeStamp = dateFormatted.set("hour", readyTimeFormatted.hour()).set("minute", readyTimeFormatted.minute()).set("second", readyTimeFormatted.second());
            setValue("readyTimeStamp", {
                date: dateFormatted.format("YYYY-MM-DD"),
                time: readyTimeFormatted.format("HH:mm:00"),
                isoTimeStamp: readyTimeStamp.format()
            });
        }

        if (date && closeTime) {
            const closeTimeStamp = dateFormatted.set("hour", closeTimeFormatted.hour()).set("minute", closeTimeFormatted.minute()).set("second", closeTimeFormatted.second());

            setValue("closeTimeStamp", {
                date: dateFormatted.format("YYYY-MM-DD"),
                time: closeTimeFormatted.format("HH:mm:00"),
                isoTimeStamp: closeTimeStamp.format()
            });
        }
    };

    // useEffect(() => {
    //     setCloseTime(dayjs(readyTime).add(3, "hours").toDate());
    // }, [readyTime]);

    const handleTimeShift = (date: Date) => {
        setCloseTime(dayjs(date).add(3, "hours").toDate());
    };

    useEffect(() => {
        setDates();
    }, [date, readyTime, closeTime]);

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Schedule Pickup</FormTitle>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-6 p-4">
                <DatePicker date={date} setDate={setDate} name="readyDate" label="Ready Date" />
                <TimePicker name="readyTime" label="Ready Time" date={readyTime} setDate={setReadyTime} onChange={handleTimeShift} />

                <Select label="Pickup Location" SelectItemList={PickupLocation} name={"pickupLocation"} defaultValue={getValues("pickupLocation") || "frontDoor"} />
                <TimePicker name="closeTime" label="Close Time" date={closeTime} setDate={setCloseTime} value={dayjs(closeTime).format("HH:mm")} />

                <div className="col-span-2">
                    <Textbox name="specialNote" label="Instructions for the driver" />
                </div>
            </CardContent>
        </Card>
    );
}
