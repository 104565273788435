import { Label } from "@/components/ui/label";
import { Checkbox as CheckboxArea } from "@/components/ui/checkbox";
import { ComponentPropsWithoutRef, ReactNode, useEffect } from "react";
import { get, useFormContext } from "react-hook-form";

interface CheckboxProps {
    label?: string | ReactNode | (string & ReactNode);
    name: string;
    defaultValue?: boolean;
    onChange?: (checked?: boolean | string) => void;
    role?: string;
}

export default function Checkbox({ name, label, defaultValue, onChange, role }: CheckboxProps) {
    const {
        formState: { errors },
        setValue
    } = useFormContext();

    const errorMessage = get(errors, name)?.message;

    const changeValue = (value: boolean | string) => {
        setValue(name, value);
    };

    {
        defaultValue &&
            useEffect(() => {
                changeValue(defaultValue);
            }, []);
    }

    return (
        <>
            <div className="flex gap-2">
                <CheckboxArea
                    name={name}
                    id={name}
                    onCheckedChange={(value) => {
                        changeValue(value);
                        {
                            onChange && onChange(value || undefined);
                        }
                    }}
                    defaultChecked={defaultValue}
                    role={role}
                />
                {label && <Label htmlFor={name}>{label}</Label>}
            </div>
            {errorMessage !== undefined ? <p className="text-xs text-red-500">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>}
        </>
    );
}
