import AppRoutes from "./routes/routes";
import { useState } from "react";
import { carrierDialog } from "@/context/CarrierDialog";
import { TourProvider } from "./onboarding/context";
import TourWrapper from "./onboarding/Wrapper";

export default function App() {
    const [open, setOpen] = useState(false);

    return (
        <TourProvider>
            <carrierDialog.Provider value={{ open, setOpen }}>
                <TourWrapper />
                <AppRoutes />
            </carrierDialog.Provider>
        </TourProvider>
    );
}
