import { create } from "zustand";

interface UseSaveAddressProps {
    saveShipper: boolean;
    saveReceiver: boolean;
    setSaveShipper: (value: boolean | undefined) => void;
    setSaveReceiver: (value: boolean | undefined) => void;
}

export const useSaveAddressStore = create<UseSaveAddressProps>((set, get) => ({
    saveShipper: false,
    saveReceiver: false,
    setSaveShipper: (value) => {
        set({ saveShipper: value });
    },
    setSaveReceiver: (value) => {
        set({ saveReceiver: value });
    }
}));
