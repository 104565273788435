import { User, onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "@/core/firebase";
import { create } from "zustand";
import { UserData } from "@shared/UserTypes";
import { getUserbyEmail } from "@/api/user/getUser";
import { updateStripeCustomer } from "@/api/user/editStripeCustomer";

type UserStore = {
    user: User | null | undefined;
    userData: UserData | null | undefined;
    isFirstTime: boolean;
    setUser: (user: User | null) => void;
    setUserData: (userData: UserData | null) => void;
    editUserData: (data) => void;
    setFirstTime: (status: boolean) => void;
    logout: () => void;
    isLoggedIn: () => boolean;
};

export const useUserStore = create<UserStore>((set, get) => ({
    user: undefined,
    userData: undefined,
    isFirstTime: false,
    setUser: (user) => {
        set({ user: user });
    },
    setUserData: (userData) => {
        set({ userData: userData });
    },
    editUserData: (data) => {
        set((state) => ({ userData: { ...state.userData, ...data } }));
    },
    setFirstTime: (status) => {
        set({ isFirstTime: status });
    },
    logout: () => {
        signOut(auth);
        set({ user: null, userData: null });
    },
    isLoggedIn: () => {
        const { user } = get();
        return user !== null;
    }
}));

onAuthStateChanged(auth, async (user) => {
    if (user) {
        useUserStore.getState().setUser(user);

        const userData = await getUserbyEmail(user.email);

        useUserStore.getState().setUserData(userData);
        userData && (await updateStripeCustomer(userData.stripe, userData.name, userData.companyName));

        const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;

        if (isNewUser) {
            useUserStore.getState().setFirstTime(true);
        } else {
            useUserStore.getState().setFirstTime(false);
        }
    } else {
        useUserStore.getState().logout();
    }
});
