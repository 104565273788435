import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Step, StepItem, Stepper, useStepper } from "@/components/ui/stepper";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import ConfirmShipment from "@/app/shipment/ConfirmShipment";
import ReceiverForm from "@/app/shipment/create-shipment/ReceiverDetails";
import ShipmentDetails from "@/app/shipment/create-shipment/ShipmentDetails";
import ShipperForm from "@/app/shipment/create-shipment/ShipperDetails";
import PickupOptions from "@/app/shipment/Pickups/PickupOptions";
import Rates from "@/app/shipment/RatesPage";
import { Clipboard } from "lucide-react";
import FedEx from "@/assets/carriers/FedExLogo.png";
import Purolator from "@/assets/carriers/PurolatorLogo.png";
import UPS from "@/assets/carriers/UPSLogo.png";
import PageTitle from "@/components/composite/Headers/PageTitle";
import { useEffect, useState } from "react";
import { FormStepContext } from "@/context/FormStepContext";
import { useFormStore } from "@/core/ShipmentFormStore";
import { cn } from "@/lib/utils";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "@/onboarding/context";
import { useMount } from "react-use";
import { Banner } from "@/components/composite/Banner";

export default function CreateShipment() {
    const [FormSteps, setFormSteps] = useState([<ShipperForm />, <ReceiverForm />, <ShipmentDetails />, <Rates />, <PickupOptions />, <ConfirmShipment />]);

    const navigate = useNavigate();
    const { confirmShipment } = useParams();

    // useEffect(() => {
    //     useFormStore.getState().removeShipment();
    // }, []);
    const [steps, setSteps] = useState([
        { label: "Shipper" },
        { label: "Receiver" },
        { label: "Packaging" },
        { label: "Rates" },
        // { label: "Customs" },
        { label: "Handoff" },
        { label: "Confirm" }
    ] satisfies StepItem[]);

    const FormStepValue = {
        FormSteps,
        setFormSteps,
        steps,
        setSteps
    };

    let stepNumber = 0;

    useEffect(() => {
        if (confirmShipment && confirmShipment === useFormStore.getState().shipment.id) {
            stepNumber = steps.length - 1;
        } else if (confirmShipment) {
            navigate("/create-shipment");
        }
    }, []);

    return (
        <div>
            <PageTitle>
                <Clipboard />
                Create Shipment
            </PageTitle>
            <FormStepContext.Provider value={FormStepValue}>
                <div className="grid gap-6">
                    <Stepper
                        initialStep={stepNumber ?? 0}
                        steps={steps}
                        variant="circle-alt"
                        size="sm"
                        styles={{
                            "step-button-container": cn(
                                "text-gray-500",
                                "data-[current=true]:border-brand-900 data-[current=true]:bg-brand-50",
                                "data-[active=true]:bg-brand-900 data-[active=true]:border-brand-900"
                            ),
                            "horizontal-step": "data-[completed=true]:[&:not(:last-child)]:after:bg-gray-700"
                        }}>
                        {steps.map(({ label }, index) => {
                            return (
                                <Step key={label} label={label}>
                                    {FormSteps[index]}
                                </Step>
                            );
                        })}
                        {/* <Footer /> */}
                    </Stepper>
                </div>
            </FormStepContext.Provider>
        </div>
    );
}

export const CarrierSelector = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-base font-medium">Carrier</CardTitle>
                <CardDescription>Select one of all of our carriers</CardDescription>
            </CardHeader>
            <CardContent>
                <ToggleGroup variant="outline" type="multiple" className="grid grid-cols-3 gap-4">
                    <ToggleGroupItem value="UPS" className="p-2.5">
                        <img src={UPS} className="h-full object-cover" />
                    </ToggleGroupItem>
                    <ToggleGroupItem value="FedEx" className="p-2.5">
                        <img src={FedEx} className="h-3/4 object-cover" />
                    </ToggleGroupItem>
                    <ToggleGroupItem value="Purolator" className="p-2.5">
                        <img src={Purolator} className="h-3/4 object-cover" />
                    </ToggleGroupItem>
                </ToggleGroup>
            </CardContent>
        </Card>
    );
};

export const FormFooter = () => {
    const stepMethods = useStepper();

    return (
        <div className="flex gap-6">
            {!stepMethods.isDisabledStep && (
                <Button type="button" onClick={stepMethods.prevStep} variant="outline" className="flex-grow">
                    Back
                </Button>
            )}
            <Button variant="default" className="flex-grow">
                Next
            </Button>
        </div>
    );
};
