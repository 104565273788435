import axios from "axios";

const getCheckoutSession = async (sessionID) => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/Stripe/checkout-session/${sessionID}`);
    return response.data;
};

const getPaymentIntent = async (paymentIntentID) => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/Stripe/payment-intent/${paymentIntentID}`);
    return response.data;
};

const getPaymentMethod = async (paymentMethodID) => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/Stripe/payment-method/${paymentMethodID}`);
    return response.data;
};

const getChargeObject = async (chargeID) => {
    const response = await axios.get(`${import.meta.env.VITE_API_URL}/Stripe/charge/${chargeID}`);
    return response.data;
};

export { getCheckoutSession, getPaymentIntent, getPaymentMethod, getChargeObject };
