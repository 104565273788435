import axios from "axios";
import useRatesStore from "@/core/RatesStore";
import { useFormStore } from "@/core/ShipmentFormStore";
import { Shipment } from "@shared/ShipmentTypes";

export const FedExCreatePickup = async (payload: Shipment) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/FedEx/create-pickup`, payload);
        console.log(response);
        return response.data.output.pickupConfirmationCode;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};

export const UPSCreatePickup = async (payload: Shipment) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/UPS/create-pickup`, payload);
        console.log(response);
        return response.data.PickupCreationResponse.PRN;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};

export const PurolatorCreatePickup = async (payload: Shipment) => {
    try {
        const response = await axios.post(`${import.meta.env.VITE_API_URL}/Purolator/create-pickup`, payload);
        console.log(response);
        return response.data.PickUpConfirmationNumber;
    } catch (error) {
        console.error(error);
        throw error; // Rethrow the error for handling it in the calling context
    }
};

export const createPickup = async (shipment: Shipment) => {
    const carrier = shipment.serviceDetails?.carrier;
    try {
        if (carrier === "FedEx") {
            return await FedExCreatePickup(shipment);
        } else if (carrier === "UPS") {
            return await UPSCreatePickup(shipment);
        } else if (carrier === "Purolator") {
            return await PurolatorCreatePickup(shipment);
        } else {
            throw new Error("missing carrier");
        }
    } catch (error) {
        console.error("Error creating pickup:", error);
        throw error; // Allow the React component to handle the error
    }
};
