import { ComponentPropsWithoutRef, ReactNode } from "react";
import { FieldValues, FormProvider, get, SubmitHandler, UseFormReturn } from "react-hook-form";

interface FormTypes extends ComponentPropsWithoutRef<"form"> {
    methods: UseFormReturn<any>;
    children: ReactNode;
    onSubmit: SubmitHandler<FieldValues>;
    className?: string | undefined;
}

export default function Form({ methods, children, onSubmit, className, ...rest }: FormTypes) {
    const errorMessage = get(methods.formState.errors, "root")?.message;

    const preventEnterSubmit = (event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} data-testid="form" {...rest}>
                {errorMessage !== undefined ? (
                    <p role="error" className="mb-2 text-xs text-red-500">
                        {errorMessage}
                    </p>
                ) : (
                    <p className="mb-2 block text-xs text-red-500"> </p>
                )}
                <div className={className}>{children}</div>
            </form>
        </FormProvider>
    );
}
