import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Shipment } from "@shared/ShipmentTypes";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { pickupDetailsSchema } from "@/schema/ShipmentSchema";
import { useForm } from "react-hook-form";
import Form from "@/components/composite/Form";
import { useToast } from "@/components/ui/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { createPickup } from "@/api/pickups/createPickup";
import { storePickupData } from "@/api/pickups/storePickup";
import { addPickupToShipment } from "@/api/pickups/updatePickups";
import CreatePickup from "@/app/shipment/Pickups/CreatePickup";
import UseExistingPickup from "@/app/shipment/Pickups/UseExistingPickup";
import { DialogHeader, DialogFooter } from "@/components/ui/dialog";
import { Dialog, DialogContent, DialogDescription } from "@/components/ui/dialog";
import { PickupData } from "@shared/PickupTypes";
import { z } from "zod";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";

export default function SchedulePickup({ shipment }: { shipment: Shipment }) {
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [openExistingDialog, setOpenExistingDialog] = useState(false);

    return (
        <>
            <Card className="h-fit border-2">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">Schedule Pick Up</CardTitle>
                    <CardDescription className="my-2">This shipment doesn't have a pickup assigned to it yet.</CardDescription>
                </CardHeader>
                <CardContent className="mt-6 grid grid-cols-2 gap-4">
                    <Button variant={"secondary"} onClick={() => setOpenScheduleDialog(!openScheduleDialog)}>
                        Schedule Pickup now
                    </Button>
                    <Button variant={"secondary"} onClick={() => setOpenExistingDialog(!openExistingDialog)}>
                        Use Existing Pickup
                    </Button>
                </CardContent>
            </Card>
            <SchedulePickupDialog shipment={shipment} open={openScheduleDialog} setOpen={setOpenScheduleDialog} />
            <ExistingPickupDialog shipment={shipment} open={openExistingDialog} setOpen={setOpenExistingDialog} />
        </>
    );
}

interface DialogProps {
    shipment: Shipment;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SchedulePickupDialog = ({ shipment, open, setOpen }: DialogProps) => {
    const { toast } = useToast();

    const [loading, setLoading] = useState(false);
    const methods = useForm<z.infer<typeof pickupDetailsSchema>>({
        resolver: zodResolver(pickupDetailsSchema),
        mode: "onChange",
        defaultValues: {
            pickupType: "pickup",
            scheduleType: "scheduleNow",
            carrier: shipment.serviceDetails?.carrier,
            pickupLocation: shipment.pickupDetails.pickupLocation,
            readyTimeStamp: {
                date: shipment.pickupDetails.readyTimeStamp?.date,
                time: shipment.pickupDetails.readyTimeStamp?.time,
                isoTimeStamp: shipment.pickupDetails.readyTimeStamp?.isoTimeStamp
            },
            closeTimeStamp: {
                date: shipment.pickupDetails.closeTimeStamp?.date,
                time: shipment.pickupDetails.closeTimeStamp?.time,
                isoTimeStamp: shipment.pickupDetails.closeTimeStamp?.isoTimeStamp
            }
        }
    });

    const handleSubmit = (data: z.infer<typeof pickupDetailsSchema>) => {
        setLoading(true);
        if (shipment) {
            console.log(data);
            shipment.pickupDetails = data;
            createPickup(shipment)
                .then(async (response) => {
                    console.log(response);
                    const pickupData: PickupData = {
                        data: response,
                        carrier: shipment.serviceDetails?.carrier ?? "",
                        shipper: shipment.shipper ?? "",
                        shipmentID: [shipment.id ?? ""],
                        uid: shipment.uid ?? "",
                        pickupDetails: data
                    };

                    console.log(pickupData.uid);
                    shipment.id && storePickupData(pickupData, shipment.id);

                    setOpen(false);
                    toast({ title: "Pickup Scheduled", description: `Your pickup is scheduled` });
                })
                .catch((e) => {
                    console.error(e);
                    toast({ title: "Pickup not Scheduled", description: "Please try again", variant: "destructive" });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen} modal={true}>
                <DialogContent
                    onClick={(e) => {
                        e.stopPropagation();
                    }}>
                    <DialogHeader>
                        <DialogDescription>
                            <p>To edit the pickup, fill out the form below </p>
                        </DialogDescription>
                    </DialogHeader>
                    <Form methods={methods} onSubmit={handleSubmit} className="grid gap-2">
                        <CreatePickup />
                        <DialogFooter>
                            <LoadingButton isLoading={loading} type="submit">
                                Schedule Pickup
                            </LoadingButton>
                        </DialogFooter>
                    </Form>
                </DialogContent>
            </Dialog>
        </>
    );
};

const ExistingPickupDialog = ({ shipment, open, setOpen }: DialogProps) => {
    const [selectedPickup, setSelectedPickup] = useState<string | undefined>();

    const handleClick = async () => {
        await addPickupToShipment(selectedPickup, shipment.id);
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogContent className="w-full">
                    <UseExistingPickup shipment={shipment} selectedPickup={selectedPickup} setSelectedPickup={setSelectedPickup} />
                    <DialogFooter>
                        <Button className="w-full" onClick={handleClick}>
                            Add to existing pickup
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};
