import logo from "@/assets/logos/SnapShipLogoNEW.svg";
import InputList from "@/components/composite/Inputs/InputList";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { resetSchema } from "@/schema/UserSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Input from "@/components/composite/Inputs/Input";
import Form from "@/components/composite/Form";

import { auth } from "@/core/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import LoadingButton from "@/components/composite/Buttons/LoadingButton";
import { useState } from "react";
// import { toast } from "sonner";

export default function ResetPassword() {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const methods = useForm<z.infer<typeof resetSchema>>({
        resolver: zodResolver(resetSchema),
        mode: "onChange"
    });

    const onSubmit = (data: z.infer<typeof resetSchema>) => {
        setIsLoading(true);
        console.log(data);
        sendPasswordResetEmail(auth, data.email)
            .then(() => {
                toast({ title: "Email sent", description: "Please check your email to reset your password.", className: "bg-success-400" });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                methods.setError("root", {
                    message: errorCode + errorMessage
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <div className="grid h-full w-full place-content-center gap-4">
                <img src={logo} className="h-10 place-self-center object-cover" />
                <Card className="mx-auto max-w-sm border-none shadow-none">
                    <CardHeader>
                        <CardTitle className="text-2xl">{"Reset Password"}</CardTitle>
                        <CardDescription>Enter your email below to reset your password</CardDescription>
                    </CardHeader>
                    <CardContent>
                        <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                            <Input label="Email" name="email" placeholder="m@email.com" />
                            <LoadingButton isLoading={isLoading} className="w-full" type="submit">
                                Send email
                            </LoadingButton>
                        </Form>

                        <div className="mt-4 text-center text-sm">
                            Remember your account?{" "}
                            <Link to="/login" className="underline">
                                Login here
                            </Link>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <Toaster />
        </>
    );
}
