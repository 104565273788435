import Joyride, { CallBackProps, STATUS } from "react-joyride";
import { Outlet, useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { useAppContext } from "./context";
import Steps from "./Steps";

export default function TourWrapper() {
    const {
        setState,
        state: { run, stepIndex, steps }
    } = useAppContext();
    const navigate = useNavigate();

    useMount(() => {
        setState({
            steps: Steps
        });
    });

    const handleCallback = (data: CallBackProps) => {
        const {
            action,
            index,
            step: {
                data: { next, previous } = {} // Default to empty object if data is undefined
            },
            type
        } = data;
        const isPreviousAction = action === "prev";
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(data.status)) {
            setState({ run: false, tourActive: false });
        }
        if (data.status === "finished" || data.status === "skipped") {
            // Check if any style changes are being made here
            console.log("Tour finished or skipped");
        }

        if (type === "step:after") {
            if (index < 2) {
                // For the first two steps and, just update the step index
                setState({ stepIndex: index + (isPreviousAction ? -1 : 1) });
            } else if (index >= 2 && index <= 3) {
                // For the third step, handle navigation and update the step index
                setState({ run: false });
                navigate(isPreviousAction && previous ? previous : next);
                setState({ run: true, stepIndex: index + (isPreviousAction && previous ? -1 : 1) });
            } else if (index === Steps.length - 1) {
                // For the last step, navigate back to the settings page (when going forward) or update step back (when going back)
                if (isPreviousAction && previous) {
                    setState({ run: true, stepIndex: index - 1 });
                } else {
                    setState({ run: false, tourActive: false });
                    navigate(next);
                }
            }
        }
    };

    return (
        <>
            <Joyride
                callback={handleCallback}
                continuous
                run={run}
                stepIndex={stepIndex}
                steps={steps}
                styles={{
                    options: {
                        zIndex: 1000,
                        primaryColor: "#2E90FA"
                    }
                }}
                showProgress
                showSkipButton
            />
            <Outlet />
        </>
    );
}
