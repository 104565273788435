import { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import { FormProvider, get, useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

import Input from "@/components/composite/Inputs/Input";
import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import Select from "@/components/composite/Inputs/Select";
import Textbox from "@/components/composite/Inputs/Textbox";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { useStepper } from "@/components/ui/stepper";
import { Textarea } from "@/components/ui/textarea";
import { useFormStore } from "@/core/ShipmentFormStore";
import { customsDetailsSchema } from "@/schema/ShipmentSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import Form from "@/components/composite/Form";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Info } from "lucide-react";
// import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import FormTitle from "@/components/composite/Headers/FormTitle";
import Checkbox from "@/components/composite/Inputs/Checkbox";
import { Alert, AlertDescription } from "@/components/ui/alert";
import CountryDropdown from "@/components/composite/Inputs/CountryInputs/CountryInput";
import { useDropdownStore } from "@/core/Dropdown";

const PayorOptions = [
    {
        name: "Shipper",
        label: "Shipper"
    },
    {
        name: "Receiver",
        label: "Receiver"
    }
];

const CustomsCurrency = [
    {
        name: "CAD",
        label: "CAD"
    },
    {
        name: "USD",
        label: "USD"
    }
];

const ReasonForExport = [
    { name: "sale", label: "Sale" },
    { name: "gift", label: "Gift" },
    { name: "return", label: "Return" },
    { name: "repair", label: "Repair" },
    { name: "sample", label: "Sample" },
    { name: "noValue", label: "This shipment has no commercial value" },
    { name: "none", label: "Other" }
];

const UnitsOptions = [
    { name: "Bag", label: "Bag" },
    { name: "Barrel", label: "Barrel" },
    { name: "Bolt", label: "Bolt" },
    { name: "Box", label: "Box" },
    { name: "Bunch", label: "Bunch" },
    { name: "Bundle", label: "Bundle" },
    { name: "Butt", label: "Butt" },
    { name: "Canister", label: "Canister" },
    { name: "Carton", label: "Carton" },
    { name: "Case", label: "Case" },
    { name: "Centimetre", label: "Centimetre" },
    { name: "Container", label: "Container" },
    { name: "Crate", label: "Crate" },
    { name: "Cylinder", label: "Cylinder" },
    { name: "Dozen", label: "Dozen" },
    { name: "Each", label: "Each" },
    { name: "Envelope", label: "Envelope" },
    { name: "Feet", label: "Feet" },
    { name: "Kilograms", label: "Kilograms" },
    { name: "Litre", label: "Litre" },
    { name: "Metre", label: "Metre" },
    { name: "Package", label: "Package" },
    { name: "Packet", label: "Packet" },
    { name: "Pairs", label: "Pairs" },
    { name: "Pallet", label: "Pallet" },
    { name: "Pieces", label: "Pieces" },
    { name: "Pounds", label: "Pounds" },
    { name: "Proof Litres", label: "Proof Litres" },
    { name: "Roll", label: "Roll" },
    { name: "Set", label: "Set" },
    { name: "Square Metres", label: "Square Metres" },
    { name: "Square Yards", label: "Square Yards" },
    { name: "Tube", label: "Tube" },
    { name: "Yard", label: "Yard" }
];

const DeclarationOptions = [
    {
        name: "none",
        label: "None"
    },
    {
        name: "EEA",
        label: "European Economic Area (EEA)"
    },
    {
        name: "standard",
        label: "Standard"
    },
    {
        name: "custom",
        label: "Custom"
    }
];

export default function CustomsDetails() {
    const { prevStep, nextStep } = useStepper();

    const [count, setCount] = useState(0); // * zero index

    const formValues = useFormStore.getState().customsDetails;

    const methods = useForm<z.infer<typeof customsDetailsSchema>>({
        resolver: zodResolver(customsDetailsSchema),
        mode: "onChange",
        defaultValues: formValues
    });

    const goPrev = () => {
        const data = methods.getValues();
        useFormStore.getState().addCustomsDetails(data);
        prevStep();
    };

    const onSubmit = (data: z.infer<typeof customsDetailsSchema>) => {
        // console.log(data);
        try {
            const validation = customsDetailsSchema.parse(data);
            console.log("Validation successful:", validation);
        } catch (error) {
            console.error("Validation failed:", error.errors);
        }
        useFormStore.getState().addCustomsDetails(data);
        console.log(useFormStore.getState().shipment);

        nextStep();
    };

    const [statement, setStatement] = useState("none"); // * statement type

    const [statementText, setStatementText] = useState(""); // * statement text

    let defaultValue = "";
    const onStatementChange = (value: string) => {
        console.log(value);
        setStatement(value);
        defaultValue =
            value === "EEA"
                ? "The exporter of the products covered by this document declares that except where otherwise clearly indicated these products are of EEA preferential origin."
                : value === "standard"
                  ? "I hereby certify that the information on this invoice is true and correct and the contents and value of this shipment is as stated above."
                  : value === "custom"
                    ? ""
                    : "";
        // console.log(defaultValue);
        setStatementText(defaultValue);
    };

    const [form, setForm] = useState(true); // * enables or disables form if there is no customs value

    const handleExportReason = (value: string) => {
        setForm(true);
        if (!form && value !== "noValue") {
            methods.setValue("customsCurrency", "");
            methods.setValue("products", []);
            methods.setValue("customsPayor", "");
        }
        if (value === "noValue") {
            setForm(false);
            methods.setValue("customsCurrency", "CAD");
            methods.setValue("products", [
                {
                    description: "1",
                    quantity: 1,
                    units: "PACKAGE",
                    numberOfPackages: 1,
                    value: 1,
                    weight: 1,
                    country: "CA"
                }
            ]);
            methods.setValue("customsPayor", "shipper");
        }
    };

    return (
        <Card>
            <CardHeader className="p-4">
                <FormTitle>Customs Details</FormTitle>
                <CardDescription>Fill out the customer form below so that you can receive a commercial invoice for your international shipment</CardDescription>
            </CardHeader>
            <CardContent className="p-4">
                <Form methods={methods} onSubmit={onSubmit} className="grid gap-6">
                    <Select label={"Reason for Export"} name={"exportReason"} SelectItemList={ReasonForExport} defaultValue={"sale"} onChange={handleExportReason} />
                    {form ? (
                        <>
                            <div className="grid w-full grid-cols-2 gap-6">
                                <div className="grid gap-4">
                                    <Label>Customs Payor</Label>
                                    <RadioButtons options={PayorOptions} className="" name="customsPayor" defaultValue="Shipper" />
                                </div>

                                {/* <Input name="shipmentValue" label="Total Value of Shipment" type="number" /> */}
                                <Select label="Customs Currency" name="customsCurrency" SelectItemList={CustomsCurrency} defaultValue="CAD" />
                            </div>

                            {/* <Select label={"Reason for Export"} name={"ExportReason"} SelectItemList={ReasonForExport} /> */}
                            <ProductDetails index={count} />
                            <Textbox label={"Comments"} name={"comments"} />
                            <Select SelectItemList={DeclarationOptions} name="declarationStatement" label="Declaration Statement" defaultValue="none" onChange={onStatementChange} />
                            {statement !== "none" && (
                                <Textbox
                                    label={"Statement Text"}
                                    name={"declarationStatementText"}
                                    value={statementText}
                                    readOnly={statement !== "custom"}
                                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                        console.log(e.target.value);
                                        setStatementText(e.target.value);
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        <Confirmation />
                    )}
                    <div className="flex gap-6">
                        <Button type="button" onClick={goPrev} variant="outline" className="flex-grow">
                            Back
                        </Button>

                        <Button variant="default" type="submit" className="flex-grow">
                            {/* Confirm Customs Information */}
                            Next
                        </Button>
                    </div>
                </Form>
            </CardContent>
        </Card>
    );
}

const Confirmation = () => {
    const {
        setValue,
        clearErrors,
        setError,
        formState: { errors }
    } = useFormContext();

    const errorMessage = get(errors, "confirmation")?.message;

    const toggleConfirmation = (value: boolean) => {
        if (value) {
            clearErrors(`confirmation`);
        } else {
            setError(`confirmation`, { type: "custom", message: "Please confirm this shipment only contains documents" });
        }
        setValue(`confirmation`, value);
    };

    return (
        <>
            <Checkbox name={`confirmation`} label="I confirm this shipment only contains documents" onChange={toggleConfirmation} />

            {/* <div className="flex gap-2">
                <Checkbox name={`confirmation`} id={`confirmation`} onCheckedChange={toggleConfirmation} />
                <Label htmlFor={`confirmation`}>I confirm this envelope only contains documents</Label>
            </div> */}

            <Alert>
                <AlertDescription>
                    <Info className="inline h-4 w-4 align-middle" />
                    The majority of international shipments require a commercial invoice. Select documents do not require a commercial invoice. Please note that certain countries may not accept
                    specific types of documents (such as credit cards, passports, ID cards, or currency) as document shipments.
                </AlertDescription>
            </Alert>
        </>
    );
};

const ProductDetails = ({ index }) => {
    const { setCountryValue } = useDropdownStore();

    const [TotalValue, setTotalValue] = useState(0);

    const [value, setValue] = useState(0);

    const [quantity, setQuantity] = useState(0);

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setValue(parseFloat(e.target.value));
        setTotalValue(parseFloat(e.target.value) * quantity);
    };

    const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setQuantity(parseFloat(e.target.value));
        setTotalValue(value * parseFloat(e.target.value));
    };

    // console.log(TotalValue);

    const [country, setCountry] = useState(""); // * Trade agreement

    useEffect(() => {
        setCountryValue("CA");
    }, []);

    const onCountryChange = (selectedCountry: string) => {
        if (selectedCountry?.toUpperCase() === "US") {
            toast("For goods that are made in Canada / US / Mexico, the goods follow the USMCA (USA) free trade agreement", {
                duration: 3000,
                closeButton: true
            });
        }
        if (selectedCountry?.toUpperCase() === "CA") {
            toast("For goods that are made in Canada / US / Mexico, the goods follow the CUSMA (Canada) free trade agreement", {
                duration: 3000,
                closeButton: true
            });
        }
        if (selectedCountry?.toUpperCase() === "MX") {
            toast("For goods that are made in Canada / US / Mexico, the goods follow the T-MEC (Mexico) free trade agreement", {
                duration: 3000,
                closeButton: true
            });
        }
        setCountry(selectedCountry?.toUpperCase());
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle className="text-base font-medium">Package {index + 1}</CardTitle>
            </CardHeader>
            {/* <h2 className="text-base">Package {index + 1}</h2> */}
            <CardContent className="grid grid-cols-2 gap-6">
                <Input label={"Description"} name={`products.${index}.description`} />
                <div className="grid gap-2">
                    {/* <Input label={<CountryToolTip />} name={`products.${index}.country`} onChange={onCountryChange} /> */}
                    <CountryDropdown name={`products.${index}.country`} label={<CountryToolTip />} onChange={onCountryChange} />
                    <CountryOptions country={country} />
                </div>
                <Select label={<UnitsToolTip />} name={`products.${index}.units`} SelectItemList={UnitsOptions} />
                <Input type="number" label={<NumberOfPackagesToolTip />} name={`products.${index}.numberOfPackages`} />
                <Input label={<HSCodeToolTip />} name={`products.${index}.HSCode`} />
                <Input label={"SKU (Optional)"} name={`products.${index}.SKU`} />
                <Input type="number" label={<WeightToolTip />} name={`products.${index}.weight`} />
                <Input type="number" label={<QuantityToolTip />} name={`products.${index}.quantity`} onChange={onQuantityChange} />
                <Input type="number" label={<ValueToolTip />} name={`products.${index}.value`} onChange={onValueChange} />
                <Input label={"Total Value"} name={`products.${index}.TotalValue`} disabled={true} value={"$" + TotalValue} />
            </CardContent>
        </Card>
    );
};

const CountryOptions = ({ country }: { country: string }) => {
    if (country === "US") {
        return (
            // <div className="flex gap-2">
            //     <Checkbox id="countryOptions" />
            //     <Label htmlFor={`countryOptions`}>I will be including a USMCA Certificate of Origin form to shipment (optional).</Label>
            // </div>
            <Checkbox name={`countryOptions`} label="I will be including a USMCA Certificate of Origin form to shipment (optional)." />
        );
    }
    if (country === "CA") {
        return (
            // <div className="flex gap-2">
            //     <Checkbox id="countryOptions" />
            //     <Label htmlFor={`countryOptions`}>I will be including a CUSMA Certificate of Origin form to shipment (optional).</Label>
            // </div>
            <Checkbox name={`countryOptions`} label="I will be including a CUSMA Certificate of Origin form to shipment (optional)." />
        );
    }
    if (country === "MX") {
        return (
            // <div className="flex gap-2">
            //     <Checkbox id="countryOptions" />
            //     <Label htmlFor={`countryOptions`}>I will be including a T-MEC Certificate of Origin form to shipment (optional)/</Label>
            // </div>
            <Checkbox name={`countryOptions`} label="I will be including a T-MEC Certificate of Origin form to shipment (optional)." />
        );
    }
    return <></>;
};

const UnitsToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            Units of Measure
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>How is this product packaged or sold (i.e., are you selling a box of pens, or individuals pens)?</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const QuantityToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            Quantity
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>How many units of this product are you shipping (i.e., how many 'units of measure')?</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const HSCodeToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            HSCode (Optional)
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>
                            The World Customs Organization (WCO) oversees a global standard called the Harmonized Item Description and Coding System (HS). This system assigns a unique 6-digit number
                            to each product that is traded internationally. Countries that use this system can easily identify and classify these products.
                        </p>{" "}
                        <a href="https://www.gov.uk/trade-tariff" target="_blank" className="text-brand-500 underline">
                            Find Harmonized Tariff Code
                        </a>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const ValueToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            Value
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>Value of a single unit, as packaged.</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};
const NumberOfPackagesToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            Number of Packages
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>Should be the same as the Number of Packages (in 'Packaging Details') unless multiple products are being shipped.</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const CountryToolTip = () => {
    return (
        <div className="flex items-center justify-start">
            Country of Origin
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>Where was this product made?</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};

const WeightToolTip = () => {
    const units = useFormStore.getState().shipmentDetails.units || "imperial";
    return (
        <div className="flex items-center justify-start">
            Weight ({units === "imperial" ? "lbs" : "kgs"})
            <TooltipProvider delayDuration={0}>
                <Tooltip>
                    <TooltipTrigger>
                        <Info className="h-4" />
                    </TooltipTrigger>
                    <TooltipContent className="w-72 text-left">
                        <p>Unit weight of the commodity.</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
};
