import RatesCard from "@/components/composite/Cards/RatesCard";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import useRatesStore from "@/core/RatesStore";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useStepper } from "@/components/ui/stepper";
import { useFormStore } from "@/core/ShipmentFormStore";
import { useFormSteps } from "@/context/FormStepContext";
import getRates from "@/api/rates";
import { Skeleton } from "@/components/ui/skeleton";
import { RatesResponse } from "@/types/ResponseTypes";
import { useUserStore } from "@/core/UserStore";
import { useToast } from "@/components/ui/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { useNavigate } from "react-router-dom";

export default function Rates({ getRatesFn = async () => await getRates() }) {
    const { prevStep, nextStep } = useStepper();
    const [rates, setRates] = useState<RatesResponse[]>([]);
    const [loading, setLoading] = useState(true);
    const { toast } = useToast();
    const navigate = useNavigate();
    const userData = useUserStore((state) => state.userData);
    const [selectedRate, setSelectedRate] = useState("");

    useEffect(() => {
        const fetchRates = async () => {
            try {
                await getRatesFn();
                const ratesStore = useRatesStore.getState();
                if (ratesStore.rates.length > 0) {
                    const result = ratesStore.rates;
                    const formStore = useFormStore.getState();
                    formStore.removePickupDetails();
                    if (formStore.customsDetails) {
                        formStore.removeCustomsDetails();
                    }
                    if (formStore.shipment.cost) {
                        formStore.editData({ cost: undefined });
                    }
                    const filteredRates = filterRate(result);
                    sortRates("rates", filteredRates);
                    setLoading(false);
                } else {
                    toast({ title: "Error fetching rates. Please try again later.", variant: "destructive" });
                }
            } catch (error) {
                console.error(error);
                toast({
                    title: "Carriers are not enabled",
                    variant: "default",
                    action: (
                        <ToastAction
                            altText="Enable Carrier"
                            onClick={() => {
                                navigate("/settings/carriers", { replace: true });
                            }}
                            className="">
                            Enable Carrier
                        </ToastAction>
                    )
                });
            }
        };

        fetchRates();
    }, [navigate, toast]);

    const filterRate = (rates: RatesResponse[]) => {
        let result = rates.filter((item, index, self) => index === self.findIndex((t) => t.ServiceCode === item.ServiceCode));
        return result;
    };

    const sortRates = (method: "rates" | "transitTimes" | "carrier" | string, rates: RatesResponse[]) => {
        if (method === "rates") {
            const sortedRates = rates.sort(sortByRates);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
        if (method === "transitTimes") {
            const sortedRates = rates.sort(sortByTimes);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
        if (method === "carrier") {
            const sortedRates = rates.sort(sortByCarrier);
            useRatesStore.getState().removeRates();
            sortedRates.forEach((rate) => useRatesStore.getState().addRates(rate));
            setRates(useRatesStore.getState().rates);
        }
    };

    const { steps } = useFormSteps();

    const editData = () => {
        useFormStore.getState().removeServiceDetails();
        console.log(useFormStore.getState().shipment);
        useRatesStore.getState().removeRates();
        prevStep();
    };

    const confirmShipment = () => {
        if (useFormStore.getState().serviceDetails.serviceCode) {
            console.log(useFormStore.getState().serviceDetails);
            // console.log("next step");
            nextStep();
        } else {
            // console.log("select rate");
        }
    };

    return (
        <>
            <Card>
                <CardHeader className="flex flex-row items-center gap-2">
                    <div className="grid items-center">
                        <CardTitle className="text-base font-medium">Estimated Rates</CardTitle>
                        <CardDescription className="mr-20 text-xs">
                            These rates are estimated based on the provided information. Carriers reserve the right to resize, reweigh packages, and reassess prices upon arrival at carrier hubs. Any
                            adjustments will be reflected on your SnapShip invoice.
                        </CardDescription>
                    </div>
                    <div className="ml-auto grid w-1/4 gap-2">
                        <Label htmlFor="sortBy">Sort by: </Label>
                        <Select
                            defaultValue="rates"
                            onValueChange={(value) => {
                                sortRates(value, rates);
                            }}>
                            <SelectTrigger className="" name="sortBy" id="sortBy">
                                <SelectValue placeholder={"Select Sort Method"} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value={"rates"}>Rates</SelectItem>
                                <SelectItem value={"carrier"}>Carrier</SelectItem>
                                <SelectItem value={"transitTimes"}>Transit Times</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                </CardHeader>

                <CardContent className="grid grid-cols-3 gap-6">
                    {!loading ? (
                        rates.map((rate, index: number) => <RatesCard Rates={rate} selectedRate={selectedRate} setSelectedRate={setSelectedRate} key={index} />)
                    ) : (
                        <Skeleton className="col-span-3 h-10 w-full" />
                    )}
                </CardContent>
            </Card>
            <div className="flex gap-6">
                <Button type="button" onClick={editData} variant="outline" className="flex-grow">
                    Back
                </Button>
                <Button variant="default" type="submit" className="flex-grow" disabled={selectedRate === ""} onClick={confirmShipment}>
                    {/* {steps[6].label !== "Customs" ? "Select Rate" : "Fill out Customs"} */}
                    {selectedRate === "" ? "Select Rate" : "Fill out Handoff"}
                </Button>
            </div>
        </>
    );
}

const sortByRates = (a: RatesResponse, b: RatesResponse) => {
    const cost1 = a.cost;
    const cost2 = b.cost;

    if (cost1 < cost2) {
        return -1;
    }

    if (cost1 > cost2) {
        return 1;
    }

    return 0;
};

const sortByTimes = (a: RatesResponse, b: RatesResponse) => {
    const time1 = a.transitTimes;
    const time2 = b.transitTimes;

    if (time1 < time2) {
        return -1;
    }

    if (time1 > time2) {
        return 1;
    }

    return 0;
};

const sortByCarrier = (a: RatesResponse, b: RatesResponse) => {
    const carrier1 = a.carrier;
    const carrier2 = b.carrier;

    return carrier1.localeCompare(carrier2);
};
