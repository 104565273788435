import { FormEventHandler, MouseEventHandler, ReactNode } from "react";
import { Button as ButtonStructure } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

interface ButtonProps {
    isLoading: boolean;
    children?: ReactNode;
    onClick?: FormEventHandler<HTMLButtonElement> | MouseEventHandler<HTMLButtonElement>;
    type?: "button" | "reset" | "submit";
    disabled?: boolean;
    className?: string;
}

export default function Button({ isLoading, children, onClick, type, className, disabled }: ButtonProps) {
    return (
        <ButtonStructure className={className} onClick={onClick} disabled={isLoading || disabled} type={type || "button"}>
            {isLoading ? (
                <>
                    <Loader2 className="h-4 w-4 animate-spin" data-testid="loading-img" />
                </>
            ) : (
                children
            )}
        </ButtonStructure>
    );
}
