import axios from "axios";
import { db } from "@/core/firebase";
import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";

import { PackageDimensions, Shipment } from "@shared/ShipmentTypes";
import { useUserStore } from "@/core/UserStore";
import { PickupData } from "@shared/PickupTypes";
import { useFormContext } from "react-hook-form";

interface Payload extends Omit<PackageDimensions, "description" | "batteries"> {
    description: string | null;
    batteries: boolean | null;
}

export const savePackage = async (data: PackageDimensions) => {
    console.log(data);
    const uid = useUserStore.getState().user?.email;
    if (!uid) {
        throw "uid not defined";
    }

    const payload: Payload = { ...data, description: data.description ? data.description : null, batteries: data.batteries ? data.batteries : null };

    try {
        await setDoc(doc(db, "packages", uid), { packages: arrayUnion(payload) }, { merge: true });

        console.log("created package");
    } catch (error) {
        throw new Error(`Failed to create package: ${error.message}`);
    }
};

export const getPackages = async () => {
    const uid = useUserStore.getState().user?.email;
    if (!uid) {
        throw "uid not defined";
    }

    try {
        const packages = await getDoc(doc(db, "packages", uid));

        if (packages.exists()) {
            // console.log("Document data:", packages.data());
            return packages.data().packages as PackageDimensions[];
        } else {
            // address.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        throw new Error(`Failed to get address: ${error.message}`);
    }
};

export const removePackage = async (data: PackageDimensions) => {
    const uid = useUserStore.getState().user?.email;
    if (!uid) {
        throw "uid not defined";
    }
    console.log(data);
    try {
        await setDoc(doc(db, "packages", uid), { packages: arrayRemove(data) }, { merge: true });
        console.log("removed package");
    } catch (error) {
        throw new Error(`Failed to create address: ${error.message}`);
    }
};

export const getPackage = async (index: number) => {
    const uid = useUserStore.getState().user?.email;
    if (!uid) {
        throw "uid not defined";
    }

    try {
        const packages = await getDoc(doc(db, "packages", uid));

        if (packages.exists()) {
            // console.log("Document data:", packages.data());
            return packages.data().packages[index] as PackageDimensions;
        } else {
            // address.data() will be undefined in this case
            console.log("No such document!");
        }
    } catch (error) {
        throw new Error(`Failed to get address: ${error.message}`);
    }
};
