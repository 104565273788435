import UPS from "@/assets/carriers/UPSLogo.svg";
import FedEx from "@/assets/carriers/FedExLogo.svg";
import Purolator from "@/assets/carriers/PurolatorLogo.svg";

const CarrierLogo = ({ carrier, className = "" }) => {
    if (carrier === "FedEx") {
        return <img src={FedEx} className={className} alt="FedEx" />;
    }
    if (carrier === "Purolator") {
        return <img src={Purolator} className={className} alt="Purolator" />;
    }
    if (carrier === "UPS") {
        return <img src={UPS} className={className} alt="UPS" />;
    }
};

export default CarrierLogo;
