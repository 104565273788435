import React, { useCallback, useContext, useEffect, useState } from "react";
import { get, useForm, useFormContext } from "react-hook-form";
import { Button } from "@/components/ui/button";
import AddressAutocomplete from "./Inputs/AddressAutocomplete";
import Input from "./Inputs/Input";
import { Address, Customer } from "@shared/ShipmentTypes";
import Checkbox from "./Inputs/Checkbox";
import { Label } from "@/components/ui/label";
// import { PhoneInput, getPhoneData } from "@/components/composite/PhoneNumber/PhoneInput";
// import PhoneInput from "./Inputs/PhoneInput";
import PhoneNumber from "./Inputs/PhoneNumber";
import CountryDropdown from "./Inputs/CountryInputs/CountryInput";
import StateDropdown from "./Inputs/CountryInputs/StateInput";
import { useDropdownStore } from "@/core/Dropdown";
import Separator from "@/components/composite/Separator";
import { getAddresses } from "@/api/addresses";
import { useUserStore } from "@/core/UserStore";
import Select, { SelectItem } from "./Inputs/Select";
import { useNavigate } from "react-router-dom";
import { validate } from "postal-codes-js";
import { FormControl, FormDescription, FormItem, FormLabel } from "../ui/form";
import { Input as UIInput } from "@/components/ui/input";
import { CircleAlert } from "lucide-react";
// import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

export const NameLabelContext = React.createContext<string | undefined>(undefined);

export default function AddressForm() {
    const {
        setValue,
        getValues,
        formState: { errors },
        reset
    } = useFormContext();

    const navigate = useNavigate();

    const [addressList, setAddressList] = useState<Customer[]>();
    const [dropdown, setDropdown] = useState<SelectItem[]>();

    const userData = useUserStore((state) => state.userData);

    const { setCountryValue, setStateValue } = useDropdownStore();

    // usePhoneNumberStore.getState().removeCountryValue();

    useEffect(() => {
        if (userData) {
            getAddresses(userData.email).then((addresses) => {
                // console.log(addresses);
                if (addresses) {
                    setAddressList(addresses);
                    const dropdownList: SelectItem[] = addresses.map((customer, index) => {
                        const addressDetails = [customer.address.street, customer.address.city, customer.address.postalCode, customer.address.stateCode, customer.address.countryCode].join(" ");
                        return {
                            name: index.toString(),
                            label: `${customer.attentionName} — ${addressDetails}`
                        };
                    });
                    setDropdown(
                        dropdownList.sort((a, b) => {
                            return a.label.localeCompare(b.label);
                        })
                    );
                }
            });
        }
    }, [userData]);

    const handleAddressBookChange = async (value: string) => {
        console.log(value);
        if (addressList) {
            reset(addressList[value]);
            setCountryValue(addressList[value].address.countryCode);
            setStateValue(addressList[value].address.stateCode);
        }
    };

    const handleNavigate = () => {
        navigate("/address", { replace: true });
    };

    return (
        <>
            {dropdown && (
                <>
                    <div className="grid grid-cols-[4fr_1fr] items-end gap-4">
                        <Select SelectItemList={dropdown} name="addressBook" label="Select an address from your address book" onChange={handleAddressBookChange} placeholder="Use Address Book" />
                        <Button className="mb-2" type="button" onClick={handleNavigate} variant="secondary">
                            Manage Address Book
                        </Button>
                    </div>
                    <Separator text="or" />
                </>
            )}
            <AddressInfo />
        </>
    );
}

export function BusinessInfo() {
    const nameLabel = useContext(NameLabelContext);

    const {
        getValues,
        setValue,
        setError,
        clearErrors,
        register,
        formState: { errors }
    } = useFormContext();

    // const phoneData = getPhoneData(phone);

    // const phoneNumber = getValues("phoneNumber");

    const [phone, setPhone] = useState("");

    const changeValue = (value: string) => {
        setPhone(value);
        setValue("phoneNumber", value);
        console.log(value);
    };

    const [country, setCountry] = useState(getValues("address.countryCode"));
    useEffect(() => {
        setCountry(getValues("address.countryCode"));
    }, [getValues("address.countryCode")]);
    // console.log(phoneNumber);

    // const errorMessage = get(errors, "phoneNumber")?.message;

    // console.log(errorMessage);
    return (
        <>
            <Input label={"Business/Company Name (optional)"} name={"companyName"} />
            <div className="grid grid-cols-2 gap-4">
                <Input label={nameLabel ? nameLabel : "Attention Name"} name={"attentionName"} />
                <Input label={"Email"} name={"email"} type={"email"} />
            </div>
            {/* <div className="grid grid-cols-[1fr_5fr] gap-4">
                <Input label={"Country Code"} name={"phoneCountryCode"} />
                <Input label={"Phone Number"} name={"phoneNumber"} />
            </div> */}
            {/* <div className="grid gap-2">
                <Label>Phone Number</Label>
                <PhoneInput />
                errorMessage !== undefined ? <p className="text-error-500 text-xs">{errorMessage}</p> : <p className="block text-xs text-red-500"> </p>} 
            </div> */}

            <PhoneNumber />
            {/* <PhoneInput /> */}

            {/* <PhoneInput value={getValues("phoneNumber")} onChange={handleOnChange} /> */}
        </>
    );
}

export function AddressInfo() {
    const {
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext();

    const [address, setAddress] = useState<Address>({
        street: getValues("address.street"),
        city: getValues("address.city"),
        postalCode: getValues("address.postalCode"),
        stateCode: getValues("address.stateCode"),
        countryCode: getValues("address.countryCode")
    });

    const { setCountryValue, setStateValue } = useDropdownStore();

    const [showInput, setShowInput] = useState(false);

    // console.log(address);

    useEffect(() => {
        setAddress(getValues("address"));
    }, [getValues("address")]);

    useEffect(() => {
        address.street !== "" && setValue("address.street", address.street);
        address.city !== "" && setValue("address.city", address.city);
        address.postalCode !== "" && setValue("address.postalCode", address.postalCode);
        address.stateCode !== "" && setValue("address.stateCode", address.stateCode);
        address.countryCode !== "" && setValue("address.countryCode", address.countryCode);

        setShowInput(address.street ? address.street !== "" : false);

        setCountryValue(address.countryCode);
        setStateValue(address.stateCode);
    }, [address]);

    // console.log(getValues());
    const handleClick = () => {
        setShowInput(!showInput);
    };

    const residentialDefaultValue = getValues("address.residential") || false;

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setShowInput(true);
        }
    }, [errors]);

    const AllInputs = () => {
        return <AddressDetails residentialDefaultValue={residentialDefaultValue} />;
    };

    return (
        <>
            {!showInput && (
                <>
                    <Label>Type in Address</Label>
                    <AddressAutocomplete address={address} setAddress={setAddress} />
                </>
            )}
            {showInput && <AllInputs />}
            <div className="grid w-full place-content-center">
                <Button type="button" variant="link" onClick={handleClick} className="m-auto">
                    {showInput ? "Show Autocomplete Input" : "Edit address manually"}
                </Button>
            </div>
        </>
    );
}

export function AddressDetails({ residentialDefaultValue }: { residentialDefaultValue?: boolean }) {
    return (
        <>
            <Input label={"Address"} name={"address.street"} />
            <div className="grid gap-2">
                <Input label={"Address Line 2 (optional)"} name={"address.line2"} />
                <FormDescription className="flex items-center">
                    <CircleAlert className="h-4" />
                    PO Box addresses are not accepted.
                </FormDescription>
            </div>

            {/* <FormItem>
                <FormLabel>Address Line 2 (optional)</FormLabel>
                <FormControl>
                    <UIInput name={"address.line2"} />
                </FormControl>
                <FormDescription className="flex items-center">
                    <CircleAlert className="h-4" />
                    PO Box addresses are not accepted.
                </FormDescription>
            </FormItem> */}

            <Input label={"City"} name={"address.city"} />
            <div className="grid grid-cols-[1fr_2fr_2fr] gap-4">
                <Input label={"Postal Code"} name={"address.postalCode"} />
                <CountryDropdown label={"Country"} name={"address.countryCode"} />
                <StateDropdown label={"State"} name={"address.stateCode"} />
            </div>
            <Checkbox name="address.residential" label="Is Residential Address" defaultValue={residentialDefaultValue} />
            <BusinessInfo />
        </>
    );
}
