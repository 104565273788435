import RadioButtons from "@/components/composite/Inputs/RadioButtons";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { ArrowDownToLine, ArrowUpToLine, CircleX, ExternalLink, Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import Select from "@/components/composite/Inputs/Select";
import InputList from "@/components/composite/Inputs/InputList";
import { memo, useContext, useEffect, useState } from "react";
import Input from "@/components/composite/Inputs/Input";
import { useFieldArray, useFormContext } from "react-hook-form";
import FormTitle from "@/components/composite/Headers/FormTitle";
import { useFormStore } from "@/core/ShipmentFormStore";

import Checkbox from "@/components/composite/Inputs/Checkbox";
// import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import ShipmentOptions from "./ShipmentOptions";
import { Button } from "@/components/ui/button";
import { PackageDimensions } from "@shared/ShipmentTypes";
import { savePackage, getPackages } from "@/api/packages";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useUserStore } from "@/core/UserStore";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "@/core/firebase";
import { DataTable } from "./saved-packages/data-table";
import { columns, PackageTableHeader } from "./saved-packages/columns";
import { useToast } from "@/components/ui/use-toast";
import { IndexContext } from "@/context/SelectedIndex";

const DeliveryFormat = [
    {
        name: "carrier",
        label: "Carrier Packaging"
    },
    {
        name: "package",
        label: "My Packaging"
    }
];

const PackageDetails = memo(function PackageDetails() {
    const { control, setValue, getValues } = useFormContext();

    const packageLength = getValues("packages");
    console.log(packageLength?.length);

    const [deliveryFormatValue, setDeliveryFormatValue] = useState<string>(useFormStore.getState().shipmentDetails.deliveryFormat || getValues("deliveryFormat"));

    const handleChange = (value: string) => {
        console.log(value);
        setDeliveryFormatValue(value);
    };

    const defaultValue = () => {
        if (deliveryFormatValue === "carrier" || deliveryFormatValue === "package") {
            return deliveryFormatValue;
        }
        return "carrier";
    };

    const array = Array(packageLength?.length ?? 1).fill(DimensionsInputList);
    // console.log(array);
    const [dimensionsInputs, setDimensionsInputs] = useState<(({ index, dimensionsInputs, setDimensionsInputs, deliveryFormatValue }: DimensionsInputList) => JSX.Element)[]>(array);

    const handleAdd = () => {
        // const value = count + 1;
        // setCount(value);
        setDimensionsInputs([...dimensionsInputs, DimensionsInputList]);
    };

    useEffect(() => {
        deliveryFormatValue === "carrier" && setDimensionsInputs([DimensionsInputList]);
    }, [deliveryFormatValue]);

    return (
        <Card>
            <CardHeader>
                <FormTitle>Package Details</FormTitle>
            </CardHeader>
            <CardContent className="grid gap-6">
                <div className="grid grid-cols-3 items-center gap-6">
                    <Select name="deliveryFormat" SelectItemList={DeliveryFormat} defaultValue={defaultValue()} onChange={handleChange} />
                    <ShipmentOptions />
                </div>
                {dimensionsInputs.map((DimInput, index) => {
                    return <DimInput key={index} index={index} dimensionsInputs={dimensionsInputs} setDimensionsInputs={setDimensionsInputs} deliveryFormatValue={deliveryFormatValue} />;
                })}
                {deliveryFormatValue === "package" && (
                    <Button type="button" onClick={handleAdd} variant={"secondary"}>
                        Add Package
                    </Button>
                )}
            </CardContent>
        </Card>
    );
});

interface DimensionsInputList {
    index: number;
    dimensionsInputs: (({ index, dimensionsInputs, setDimensionsInputs }: { index: any; dimensionsInputs: any; setDimensionsInputs: any }) => JSX.Element)[];
    setDimensionsInputs: React.Dispatch<React.SetStateAction<(({ index, dimensionsInputs, setDimensionsInputs }: { index: any; dimensionsInputs: any; setDimensionsInputs: any }) => JSX.Element)[]>>;
    deliveryFormatValue: string;
}

const DimensionsInputList = ({ index, dimensionsInputs, setDimensionsInputs, deliveryFormatValue }: DimensionsInputList) => {
    const { setValue, getValues } = useFormContext();

    const { remove } = useFieldArray({ name: "packages" });

    const toggleBatteries = (value: boolean) => {
        setValue(`packages.${index}.batteries`, value);
    };

    const handleRemove = () => {
        const newDimensionsInputs = dimensionsInputs.filter((_, i) => i !== index);

        // Update the indices of the remaining packages
        newDimensionsInputs.forEach((_, newIndex) => {
            if (newIndex >= index) {
                // Update the form values to shift the remaining inputs ahead
                setValue(`packages.${newIndex}.weight`, getValues(`packages.${newIndex + 1}.weight`));
                setValue(`packages.${newIndex}.length`, getValues(`packages.${newIndex + 1}.length`));
                setValue(`packages.${newIndex}.width`, getValues(`packages.${newIndex + 1}.width`));
                setValue(`packages.${newIndex}.height`, getValues(`packages.${newIndex + 1}.height`));
                setValue(`packages.${newIndex}.batteries`, getValues(`packages.${newIndex + 1}.batteries`));
                setValue(`packages.${newIndex}.description`, getValues(`packages.${newIndex + 1}.description`));
            }
        });

        // Remove the last package values (since it has been shifted)
        setValue(`packages.${newDimensionsInputs.length}`, null);
        remove(newDimensionsInputs.length);
        // setValue(`packages.${newDimensionsInputs.length}.weight`, undefined);
        // setValue(`packages.${newDimensionsInputs.length}.length`, undefined);
        // setValue(`packages.${newDimensionsInputs.length}.width`, undefined);
        // setValue(`packages.${newDimensionsInputs.length}.height`, undefined);
        // setValue(`packages.${newDimensionsInputs.length}.batteries`, undefined);
        // setValue(`packages.${newDimensionsInputs.length}.description`, undefined);

        // Update the state
        setDimensionsInputs(newDimensionsInputs);
    };

    console.log(getValues("packages"));
    const { toast } = useToast();

    const handleSave = () => {
        const packages: PackageDimensions = getValues(`packages.${index}`);
        if (packages.weight) {
            console.log(packages);
            savePackage(packages);
            toast({ description: "Package saved", className: "bg-success-400" });
        } else {
            toast({ title: "Package not saved", description: "Missing package dimension", variant: "destructive" });
        }
    };

    const [open, setOpen] = useState(false);

    return (
        <IndexContext.Provider value={index}>
            <Card>
                <CardHeader className="text-base">Package {index + 1}</CardHeader>
                <CardContent className="grid gap-4">
                    <div className="grid grid-cols-4 gap-6">
                        <Input label={"Weight"} name={`packages.${index}.weight`} type="number" />
                        <Input label={"Length"} name={`packages.${index}.length`} type="number" />
                        <Input label={"Width"} name={`packages.${index}.width`} type="number" />
                        <Input label={"Height"} name={`packages.${index}.height`} type="number" />
                        {/* <Input label={"Description"} name={`packages.${index}.description`} className="col-span-2" /> */}
                    </div>
                    <Checkbox name={`packages.${index}.batteries`} label="My package contains batteries" onChange={toggleBatteries} />
                    {deliveryFormatValue === "package" && (
                        <div className="flex place-content-end gap-2">
                            {dimensionsInputs.length > 1 && (
                                <Button variant={"link"} type="button" onClick={handleRemove} className="text-error-500 flex w-fit gap-1">
                                    <CircleX className="mr-2 h-4 w-4" />
                                    Remove Package
                                </Button>
                            )}
                            <Button variant={"link"} type="button" onClick={() => setOpen(!open)} className="text">
                                <ExternalLink className="mr-2 h-4 w-4" />
                                Open Saved Packaging Dimensions
                            </Button>
                            <Button variant={"link"} type="button" onClick={handleSave} className="text text-success-600 w-fit">
                                <ArrowDownToLine className="mr-2 h-4 w-4" />
                                Save Package
                            </Button>
                        </div>
                    )}
                </CardContent>
                {/* <div className="flex gap-2">
                <Checkbox name={`packages.${index}.batteries`} id={`packages.${index}.batteries`} onCheckedChange={toggleBatteries} />
                <Label htmlFor={`packages.${index}.batteries`}>My package contains batteries</Label>
            </div> */}
                <PackageList open={open} setOpen={setOpen} index={index} />
            </Card>
        </IndexContext.Provider>
    );
};

interface PackagesList {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    index?: number;
}
const PackageList = ({ open, setOpen }: PackagesList) => {
    const uid = useUserStore.getState().user?.email;

    const [data, setData] = useState<PackageTableHeader[]>([]);

    useEffect(() => {
        if (uid) {
            const unsub = onSnapshot(doc(db, "packages", uid), (doc) => {
                const docData = doc.data();
                console.log("Current data: ", docData);
                if (docData) {
                    const packagesList = docData.packages as PackageDimensions[];

                    setData(
                        packagesList.map((value, index) => {
                            return {
                                weight: value.weight,
                                length: value.length,
                                height: value.height,
                                width: value.width
                            } as PackageTableHeader;
                        })
                    );
                    // setData();
                }
            });
            return () => {
                unsub();
                console.log("unsubbed");
            };
        }
    }, []);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Saved Packages</DialogTitle>
                    <DialogDescription>Save, Edit and Remove packages here</DialogDescription>
                </DialogHeader>
                <DataTable data={data} columns={columns} />
            </DialogContent>
        </Dialog>
    );
};

export default PackageDetails;
