import { Customer, ServiceDetails } from "@shared/ShipmentTypes";
import { useEffect, useState } from "react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card";
import { Skeleton } from "../../ui/skeleton";
import CarrierLogo from "../CarrierLogo";
import locateCenter from "@/api/getDropoffLocations";
import { LocationResponse } from "@/types/ResponseTypes";
import { MapPin } from "lucide-react";

interface DropoffLocation {
    carrier: ServiceDetails["carrier"];
    address?: Customer;
}

export default function DropoffLocation({ carrier, address }: DropoffLocation) {
    const [loading, setLoading] = useState(true);

    const [locations, setLocation] = useState<LocationResponse[]>();

    useEffect(() => {
        const payload: Customer = {
            phoneNumber: "6476869450",
            companyName: "SnapShip",
            attentionName: "mahir",
            email: "mahirkhandaker@gmail.com",
            address: {
                postalCode: "M1E 1G7",
                countryCode: "CA",
                city: "Scarborough",
                stateCode: "ON",
                street: "188 Toynbee Trail"
            }
        };
        locateCenter(carrier, payload)
            .then((response) => {
                setLocation(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    return (
        <>
            <Card className="h-fit">
                <CardHeader>
                    <CardTitle className="text-2xl font-semibold">
                        <CarrierLogo carrier={carrier} className={"mb-3.5 h-4 object-cover"} />
                        Dropoff Locations
                    </CardTitle>
                    {/* <CardDescription className="my-2"></CardDescription> */}
                </CardHeader>
                <CardContent className="grid w-full gap-4">{locations ? locations.map((location) => <LocationDetails {...location} />) : <Skeleton className="h-10 w-full" />}</CardContent>
            </Card>
        </>
    );
}

const LocationDetails = (location: LocationResponse) => {
    return (
        <div className="flex w-full gap-2">
            <MapPin />
            <div className="flex flex-1 flex-col">
                <p>{location.details.companyName}</p>
                <p>{location.details.address.street}</p>
                <p>
                    {location.details.address.city} {location.details.address.stateCode} {location.details.address.postalCode}
                </p>
            </div>
            <div>{location.distance}KM away</div>
        </div>
    );
};
