import Logout from "@/app/auth/LogoutButton";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";
import { NavLink, useNavigate } from "react-router-dom";
import { Home, Clipboard, Package, LineChart, LucideProps, Settings2, CircleUser, LogOut, Truck, BookUser, ShoppingCart } from "lucide-react";
import { createElement } from "react";
import { useUserStore } from "@/core/UserStore";
import { Button } from "../ui/button";
import { Separator } from "@/components/ui/separator";

interface Pages {
    icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;
    title: string;
    link: string;
}

const topPages: Pages[] = [
    // {
    //     icon: Home,
    //     title: "Home",
    //     link: "/"
    // },
    {
        icon: Clipboard,
        title: "Create Shipment",
        link: "/create-shipment"
    },
    {
        icon: Package,
        title: "Shipments",
        link: "/shipments"
    },
    // {
    //     icon: ShoppingCart,
    //     title: "Products",
    //     link: "/products"
    // },
    // {
    //     icon: LineChart,
    //     title: "Billing",
    //     link: "/billing"
    // },
    {
        icon: Truck,
        title: "Carriers",
        link: "/settings/carriers"
    }
];

const bottomPages: Pages[] = [
    {
        icon: BookUser,
        title: "Address Book",
        link: "/address"
    },
    {
        icon: Settings2,
        title: "Settings",
        link: "/settings"
    },
    {
        icon: CircleUser,
        title: "Support",
        link: "/support"
    }
];

export default function NavBar() {
    const user = useUserStore((state) => state.user);
    const logout = useUserStore((state) => state.logout);

    const navigate = useNavigate();

    async function handleLogout() {
        try {
            logout();
            navigate("/login", { replace: true });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div className="relative flex flex-col border-r bg-slate-100/30 ">
                <div className="mt-6 flex flex-col gap-4 px-4">
                    <ListPages pages={topPages} />
                </div>
                <div className="mb-6 mt-auto flex flex-col gap-4 px-4">
                    <Separator />
                    <ListPages pages={bottomPages} />
                    {/* <Button onSubmit={handleLogout} className="flex gap-2">
                <LogOut className="h-4 w-4" />
                Logout
            </Button> */}
                </div>
            </div>
        </>
    );
}

const ListPages = ({ pages }: { pages: Pages[] }) =>
    pages.map((page, index) => (
        <NavLink
            to={page.link}
            className={({ isActive }) =>
                isActive ? "flex items-center gap-3 rounded-lg bg-slate-100 px-3 py-3" : "hover:text-primary flex items-center gap-3 rounded-lg bg-transparent px-3 py-3 text-gray-500 "
            }
            key={index}
            replace={true}>
            {createElement(page.icon, { className: "h-4 w-4" })}
            {page.title}
        </NavLink>
    ));

// export default function NavBar() {
//     // console.log(useAuthStore.getState().accessToken);

//     const listPages = pages.map((page, index) => (
//         <NavigationMenuItem key={index}>
//             <NavigationMenuLink href={page.link}>{page.title}</NavigationMenuLink>
//         </NavigationMenuItem>
//     ));
//     return (
//         <NavigationMenu>
//             <NavigationMenuList>
//                 {listPages}
//                 <NavigationMenuItem>
//                     <Logout />
//                 </NavigationMenuItem>
//             </NavigationMenuList>
//         </NavigationMenu>
//     );
// }
